import { ReactNode, useState } from 'react';

import cs from 'classnames';

import styles from './Tooltip.module.scss';

export enum TooltipPosition {
  EAST = 'east',
  NORTH = 'north',
  SOUTH = 'south',
  WEST = 'west'
}

export enum TooltipArrowPosition {
  END = 'end',
  MIDDLE = 'middle',
  START = 'start'
}

interface TooltipProps {
  arrow?: TooltipArrowPosition;
  children: ReactNode;
  disabled?: boolean;
  position?: TooltipPosition;
  testId?: string;
  text: string;
}

export const Tooltip = ({
  text,
  position = TooltipPosition.SOUTH,
  arrow = TooltipArrowPosition.MIDDLE,
  disabled = true,
  children,
  testId
}: TooltipProps) => {
  const [show, setShow] = useState(false);

  const textClass = cs({
    [styles.tooltip]: true,
    [styles.show]: show && !disabled,
    [styles[position]]: true,
    [styles[arrow]]: true
  });

  return (
    <div className={styles.wrapper} role="tooltip" aria-label="tooltip">
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
      <span className={textClass} data-testid={testId}>
        {text}
      </span>
    </div>
  );
};
