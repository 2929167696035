import cs from 'classnames';

import styles from './RecordingButton.module.scss';

import { ButtonState, ButtonType } from '../../../constants/buttons';
import { RecordingIcon, StopRecordingIcon } from '../../Icons/Icons.stories';

export interface RecordButtonProps {
  onClick?: () => void | Promise<void>;
  recording: boolean;
  state?: ButtonState;
  type?: ButtonType;
}

export const RecordButton = ({
  recording,
  state = ButtonState.Enabled,
  type = ButtonType.Button,
  onClick
}: RecordButtonProps) => (
  <button
    type={type}
    className={cs({
      [styles.button]: true,
      [styles[state]]: true
    })}
    disabled={state === ButtonState.Loading || state === ButtonState.Disabled}
    onClick={onClick}
  >
    {recording ? <StopRecordingIcon /> : <RecordingIcon />}
    {recording ? 'Recording...' : 'Record'}
  </button>
);
