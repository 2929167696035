import colors from '../../../styles/colors.module.scss';

import { XMLNS } from '../../../constants/Icons';
import { BaseIcon, IconProps } from '../BaseIcon/BaseIcon';

export const Screen = ({ color = colors.black, scale = 1 }: IconProps) => (
  <BaseIcon>
    <svg
      width={scale * 24}
      height={scale * 24}
      viewBox="0 -960 960 960"
      fill={color}
      xmlns={XMLNS}
    >
      <path d="M320-120v-80h80v-80H160q-33 0-56.5-23.5T80-360v-400q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v400q0 33-23.5 56.5T800-280H560v80h80v80H320ZM160-360h640v-400H160v400Zm0 0v-400 400Z" />
    </svg>
  </BaseIcon>
);
