import styles from './HelpModal.module.scss';

import packageJson from '../../../../package.json';
import { ButtonState } from '../../../constants/buttons';
import { Accordion } from '../../Accordion/Accordion';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { Modal } from '../../Modal/Modal';

const HELP_QUESTIONS = [
  {
    title: 'Can I redo my recordings?',
    content:
      'Yes, just stop your current recording, once it has finished uploading you can leave a message for our editors to ignore the recording and click restart recording.'
  },
  {
    title: "I can't see myself?",
    content:
      'Make sure you have a camera connect and have given Cloud Present access to your camera. You can usually see the permissions in the address bar.'
  },
  {
    title: "I can't add any assets?",
    content:
      'If you have shared your screen, you will be required to upload a presentation file. This is to ensure we can make you presentation as high quality as possible. You can upload any file format you want. There is a limit of 10 files, each with a size limit of 1GB.'
  }
];

export interface HelpModalProps {
  onCloseClicked?: () => void;
  open: boolean;
}

export const HelpModal = ({ open, onCloseClicked }: HelpModalProps) => (
  <Modal
    open={open}
    title="Help"
    modalId="help-modal-id"
    onCancel={onCloseClicked}
    rightButtons={
      <PrimaryButton
        text="Close"
        state={ButtonState.Enabled}
        onClick={onCloseClicked}
      />
    }
  >
    <section className={styles.container}>
      <Accordion id="help-accordion" items={HELP_QUESTIONS} />
      <section>
        If your issue is still not resolved, feel free to email us at{' '}
        <a href="mailto:hello@cloudpresent.co">hello@cloudpresent.co</a>
      </section>
      <section>Platform Version: {packageJson.version}</section>
    </section>
  </Modal>
);
