import { ElementRef, useEffect, useRef } from 'react';

import { HMSMessage } from '@100mslive/react-sdk';

import styles from './ParticipantChat.module.scss';

import { ButtonState } from '../../constants/buttons';
import { Roles } from '../../constants/roles';
import { SecondaryIconButton } from '../IconButtons/SecondaryIconButton/SecondaryIconButton';
import { Close } from '../Icons/Close/Close';
import { ChatMessage } from './ChatMessage/ChatMessage';
import { SendMessage } from './SendMessage/SendMessage';

export interface ParticipantChatProps {
  close: () => void;
  currentUserId?: string;
  isOpen: boolean;
  messages: HMSMessage[];
  sendMessage: (message: string) => Promise<void>;
}

export const ParticipantChat = ({
  messages,
  currentUserId,
  isOpen,
  sendMessage,
  close
}: ParticipantChatProps) => {
  const messagesEndRef = useRef<ElementRef<'div'>>(null);

  useEffect(() => {
    scrollToLatestMessage();
  }, [messagesEndRef]);

  useEffect(() => {
    if (!isOpen) {
      scrollToLatestMessage();
    }
  }, [messages]);

  const scrollToLatestMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMessageSent = async (message: string) => {
    await sendMessage(message);
    scrollToLatestMessage();
  };

  return (
    <section className={styles.wrapper}>
      <hgroup className={styles.heading}>
        <h3>Session chat</h3>
        <SecondaryIconButton
          icon={<Close />}
          state={ButtonState.Enabled}
          onClick={close}
        />
      </hgroup>
      {currentUserId && (
        <>
          <div className={styles.messages}>
            {messages.map((message) => (
              <ChatMessage
                key={`chat-message-${message.id}`}
                message={message}
                isSender={message.sender === currentUserId}
                isHost={message.senderRole === Roles.Host}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
          <SendMessage sendMessage={handleMessageSent} />
        </>
      )}
    </section>
  );
};
