import { ElementRef } from 'react';

import styles from './ScreenDisplay.module.scss';

import { LottieAnimationType } from '../../constants/LottieAnimations';
import { LottieAnimation } from '../LottieAnimation/LottieAnimation';

export interface ScreenDisplayProps {
  isRecording: boolean;
  participantName: string;
  videoOn: boolean;
  videoRef: (instance: ElementRef<'video'> | null) => void;
}

export const ScreenDisplay = ({
  participantName,
  isRecording,
  videoRef
}: ScreenDisplayProps) => {
  return (
    <section className={styles.container}>
      <div className={styles.innerContainer}>
        <video
          className={styles.video}
          ref={videoRef}
          autoPlay
          playsInline
          muted={false}
        />
      </div>

      <div className={styles.participantName}>
        {`${`${participantName}'s`} screen`}
      </div>

      {isRecording && (
        <div className={styles.recording}>
          <LottieAnimation
            animation={LottieAnimationType.RecordingDot}
            loop={true}
          />
        </div>
      )}
    </section>
  );
};
