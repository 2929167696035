import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  HMSNotificationTypes,
  selectLocalPeer,
  useHMSNotifications
} from '@100mslive/react-sdk';

import {
  DEFAULT_NOTIFICATION_TIMEOUT,
  MESSAGE_NOTIFICATION_TIMEOUT
} from '../constants/notifications';
import { Roles } from '../constants/roles';
import { hmsActions, hmsStore } from '../hms';
import { useAppState } from '../providers/AppState';
import { useRecordingContext } from '../providers/RecordingProvider';
import { Toast, ToastType } from '../stories/Toast/Toast';

interface NotificationProps {
  isChatShowing: boolean;
  resetTimer: () => void;
}

export default function useNotifications({
  isChatShowing,
  resetTimer
}: NotificationProps) {
  const navigate = useNavigate();

  const notification = useHMSNotifications();
  const localPeer = hmsStore.getState(selectLocalPeer);

  const { sessionData, isGroup } = useAppState();
  const { isRecording, toggleRecording } = useRecordingContext();

  const showNotification = (
    id: number,
    title: string,
    subtitle?: string,
    type: ToastType = ToastType.Info,
    messageNotification: boolean = false
  ) =>
    toast(<Toast title={title} subtitle={subtitle} type={type} />, {
      toastId: id,
      autoClose:
        type === ToastType.Error
          ? false
          : messageNotification
            ? MESSAGE_NOTIFICATION_TIMEOUT
            : DEFAULT_NOTIFICATION_TIMEOUT
    });

  useEffect(() => {
    if (!notification) {
      return;
    }

    // you can use the following to show appropriate toast notifications for example
    switch (notification.type) {
      case HMSNotificationTypes.PEER_JOINED:
        showNotification(
          notification.id,
          `${notification.data.name} joined the session`
        );
        break;
      case HMSNotificationTypes.PEER_LEFT:
        showNotification(
          notification.id,
          `${notification.data.name} left the session`
        );
        break;
      case HMSNotificationTypes.NEW_MESSAGE:
        if (
          notification.data.senderUserId !== localPeer?.id &&
          !isChatShowing
        ) {
          showNotification(
            notification.id,
            `New message from ${notification.data.senderName}`,
            notification.data.message as string
          );
        }
        break;
      case HMSNotificationTypes.ERROR:
        if (notification.data.code === 3001) {
          // user cancelled screen share
        } else if (notification.data.code === 3011) {
          showNotification(
            notification.id,
            'System denied permission to access capture device',
            'Please check you settings and enable your browser to capture your screen',
            ToastType.Error
          );
        } else {
          showNotification(
            notification.id,
            `Error [${notification.data.code}]`,
            notification.data.message
          );
        }
        // TODO: Log with Sentry
        break;
      case HMSNotificationTypes.RECONNECTING:
        showNotification(notification.id, 'Reconnecting');
        break;
      case HMSNotificationTypes.RECONNECTED:
        showNotification(notification.id, 'Reconnected');
        break;
      case HMSNotificationTypes.NAME_UPDATED:
      case HMSNotificationTypes.METADATA_UPDATED:
      case HMSNotificationTypes.ROLE_UPDATED:
        console.log(
          `peer updated(${notification.type}), new peer=`,
          notification.data
        );
        break;
      case HMSNotificationTypes.TRACK_DEGRADED:
        console.log(
          `track - ${notification.data.id} degraded due to poor network`
        );
        break;
      case HMSNotificationTypes.TRACK_RESTORED:
        console.log(`track - ${notification.data.id} recovered`);
        break;
      case HMSNotificationTypes.ROOM_ENDED: {
        if (localPeer?.roleName !== Roles.Host) {
          showNotification(notification.id, 'Session has been ended');
        }

        void hmsActions.leave();
        navigate(`/session-ended/${sessionData?.uploadId}`);
        break;
      }
      case HMSNotificationTypes.REMOVED_FROM_ROOM:
        console.log(`removed from room, reason - ${notification.data.reason}`);
        break;
      case HMSNotificationTypes.DEVICE_CHANGE_UPDATE: {
        console.log(`device changed - ${notification.data?.type}`);
        const isAudioOrVideo = ['audioInput', 'video'].includes(
          notification.data?.type ?? ''
        );
        if (
          localPeer?.roleName === Roles.Host &&
          isRecording &&
          isAudioOrVideo
        ) {
          showNotification(
            notification.id,
            `Recording has been stopped as ${isGroup ? 'a participant has changed a device' : 'a device has been changed'}`
          );

          toggleRecording();
          resetTimer();
        }
        break;
      }
      case HMSNotificationTypes.CHANGE_TRACK_STATE_REQUEST: {
        const { enabled, requestedBy, track } = notification.data;
        if (enabled) {
          showNotification(
            notification.id,
            `${requestedBy?.name} (host) has requested you to switch your ${track.type === 'audio' ? 'microphone' : 'camera'} on`
          );
        } else {
          showNotification(
            notification.id,
            `${requestedBy?.name} (host) has switched your ${track.type === 'audio' ? 'microphone' : 'camera'} off`
          );
        }
        break;
      }
      default:
        break;
    }
  }, [notification]);
}
