import { ElementRef, useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import styles from './LocalScreenDisplay.module.scss';

import { LottieAnimationType } from '../../constants/LottieAnimations';
import { hmsActions } from '../../hms';
import useGetAudioStream from '../../hooks/useGetAudioStream';
import { useMediaRecorderContext } from '../../providers/MediaRecorderProvider';
import { LottieAnimation } from '../LottieAnimation/LottieAnimation';

export interface LocalScreenDisplayProps {
  audioTrack: string | undefined;
  isGuest: boolean;
  isRecording: boolean;
  videoOn: boolean;
  videoTrack: string | undefined;
}

export const LocalScreenDisplay = ({
  videoOn,
  videoTrack,
  audioTrack,
  isGuest,
  isRecording
}: LocalScreenDisplayProps) => {
  const { createScreenMediaRecorder, stopScreenMediaRecorder } =
    useMediaRecorderContext();
  const { audioStream } = useGetAudioStream(audioTrack);

  const localScreenRef = useRef<ElementRef<'video'> | null>(null);

  const [isAttached, setIsAttached] = useState(false);

  useEffect(() => {
    return () => stopScreenMediaRecorder();
  }, []);

  useEffect(() => {
    if (videoOn && videoTrack && localScreenRef.current && !isAttached) {
      hmsActions.attachVideo(videoTrack, localScreenRef.current);
      setIsAttached(true);
    } else if (!videoOn && videoTrack && localScreenRef.current && isAttached) {
      hmsActions.detachVideo(videoTrack, localScreenRef.current);
      setIsAttached(false);
    }
  }, [videoTrack, videoOn]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (!isGuest && localScreenRef.current && videoOn) {
        const videoElement = localScreenRef.current;
        const videoStream: MediaStream = (videoElement as any).captureStream();

        const stream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...(audioStream?.getAudioTracks() || [])
        ]);

        createScreenMediaRecorder(new MediaRecorder(stream));
      }
    };

    const videoElement = localScreenRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata
        );
      }
    };
  }, [localScreenRef, videoOn, audioStream]);

  const containerClass = cs({
    [styles.container]: true,
    [styles.loaded]: isAttached
  });

  return (
    <section className={containerClass}>
      <div className={styles.innerContainer}>
        <video
          className={styles.video}
          ref={localScreenRef}
          autoPlay
          playsInline
          muted={true}
        />
        <div className={styles.participantName}>Your screen</div>

        {isRecording && !isGuest && (
          <div className={styles.recording}>
            <LottieAnimation
              animation={LottieAnimationType.RecordingDot}
              loop={true}
            />
          </div>
        )}
      </div>
    </section>
  );
};
