import { ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Cookies from 'universal-cookie';

import styles from './SessionValidation.module.scss';

import { COOKIE_CONFIG } from '../../config/cookies';
import { LottieAnimationType } from '../../constants/LottieAnimations';
import { Size } from '../../constants/Size';
import { ButtonState } from '../../constants/buttons';
import { useAppState } from '../../providers/AppState';
import { PrimaryButton } from '../../stories/Buttons/PrimaryButton/PrimaryButton';
import { LoadingSpinnerIcon } from '../../stories/Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';
import { LottieAnimation } from '../../stories/LottieAnimation/LottieAnimation';

interface SessionValidationProps {
  children?: ReactNode;
}

export const SessionValidation = ({ children }: SessionValidationProps) => {
  const navigate = useNavigate();
  const { loading, clearLoading, setError, error, joinData, sessionData } =
    useAppState();
  const params = useParams<{
    sessionId: string;
  }>();
  const cookies = new Cookies(null, { path: '/' });
  const eventUrl = cookies.get(COOKIE_CONFIG.EVENT_URL.NAME) as string | null;

  useEffect(() => {
    function validate() {
      // Check that we have the join data
      if (!joinData) {
        setError('Something went wrong, join again');

        return;
      }

      // Check that we have the session data
      if (!sessionData) {
        setError('Something went wrong, join again');

        return;
      }

      // Check that the session ID matches
      if (sessionData.uploadId !== params.sessionId) {
        setError('Something went wrong, join again');

        return;
      }

      clearLoading();
    }

    validate();
  }, [params, joinData, sessionData]);

  const handleJoinAgain = () => {
    if (eventUrl) {
      clearLoading();
      navigate(eventUrl);
    }
  };

  if (loading) {
    return (
      <section className={styles.wrapper}>
        <div className={styles.loadingWrapper}>
          <div className={styles.loader}>
            <LoadingSpinnerIcon color="white" size={Size.Large} />
          </div>
          <h1>{loading}</h1>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className={styles.wrapper}>
        <div className={styles.loadingWrapper}>
          <div className={styles.error}>
            <LottieAnimation
              animation={LottieAnimationType.Error}
              loop={false}
            />
          </div>
          <h1>{error}</h1>
          {eventUrl && (
            <PrimaryButton
              text="Restart Session"
              state={ButtonState.Enabled}
              onClick={handleJoinAgain}
            />
          )}
        </div>
      </section>
    );
  }

  return <>{children}</>;
};
