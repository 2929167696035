import styles from './Rating.module.scss';

export interface RatingProps {
  label: string;
  onChange: (value: number) => void;
  rating: number;
}

export const Rating = ({ label, rating, onChange }: RatingProps) => {
  const numberEmptyStars = 5 - rating;

  return (
    <div className={styles.wrapper}>
      <label htmlFor="rating" className={styles.label}>
        {label}
      </label>

      <section id="rating" className={styles.rating}>
        {Array(rating)
          .fill(null)
          .map((_, index) => (
            <div
              key={`rating-filled-${index}`}
              className={styles.star}
              onClick={() => onChange(index + 1)}
            >
              ⭐️
            </div>
          ))}
        {Array(numberEmptyStars)
          .fill(null)
          .map((_, index) => (
            <div
              key={`rating-empty-${index}`}
              className={styles.starEmpty}
              onClick={() => onChange(rating + index + 1)}
            >
              ⭐️
            </div>
          ))}
      </section>
    </div>
  );
};
