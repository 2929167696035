import { useCallback, useEffect, useState } from 'react';

import { getDatabase, off, onValue, ref, set } from 'firebase/database';

export default function useSomeoneScreenSharing(
  eventId?: string,
  groupId?: string
) {
  const [someoneScreenSharing, setSomeoneScreenSharing] = useState(false);

  const recordingGroupRef = ref(
    getDatabase(),
    `groupRecordings/${eventId}/${groupId}/screenSharing`
  );

  useEffect(() => {
    if (eventId && groupId) {
      onValue(recordingGroupRef, (snapshot) => {
        const data = snapshot.val() as boolean;
        setSomeoneScreenSharing(data);
      });

      return () => off(recordingGroupRef);
    }
  }, []);

  useEffect(() => {
    if (eventId && groupId) {
      set(recordingGroupRef, someoneScreenSharing);
    }
  }, [someoneScreenSharing]);

  const toggleScreenSharing = useCallback(() => {
    setSomeoneScreenSharing((c) => !c);
  }, []);

  return { someoneScreenSharing, toggleScreenSharing, setSomeoneScreenSharing };
}
