import cs from 'classnames';

import colors from '../../../../../styles/colors.module.scss';
import styles from './SelectOption.module.scss';

import { Check } from '../../../../Icons/Check/Check';

export interface Option {
  label: string;
  value: string;
}

export interface SelectOptionOpProps {
  onChange: (selected: Option) => void;
  option: Option;
  selected?: Option;
}

export const SelectOption = ({
  selected,
  option,
  onChange
}: SelectOptionOpProps) => {
  const wrapperClass = cs({
    [styles.wrapper]: true,
    [styles.selected]: option.value === selected?.value
  });

  return (
    <li
      key={option.value}
      id={option.value}
      className={styles.option}
      onClick={() => onChange(option)}
    >
      <div className={wrapperClass}>
        {option.label}
        {option.value === selected?.value && (
          <Check color={colors._success} scale={0.8} />
        )}
      </div>
    </li>
  );
};
