import cs from 'classnames';

import styles from './SecondaryButton.module.scss';

import { ButtonState, ButtonType } from '../../../constants/buttons';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';

export interface SecondaryButtonProps {
  formId?: string;
  onClick?: () => void | Promise<void>;
  state?: ButtonState;
  text: string;
  type?: ButtonType;
}

export const SecondaryButton = ({
  text,
  formId,
  state = ButtonState.Enabled,
  type = ButtonType.Button,
  onClick
}: SecondaryButtonProps) => (
  <button
    form={formId}
    type={type}
    className={cs({
      [styles.button]: true,
      [styles[state]]: true
    })}
    disabled={state === ButtonState.Loading || state === ButtonState.Disabled}
    onClick={onClick}
  >
    {state === ButtonState.Loading && <LoadingSpinnerIcon color="white" />}
    {text}
  </button>
);
