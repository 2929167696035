import { Computer } from '../stories/Icons/Computer/Computer';
import { Screen } from '../stories/Icons/Screen/Screen';
import { VisibleOff } from '../stories/Icons/VisibleOff/VisibleOff';

export const SCREEN_SHARE_MODAL_ID = 'screen-share-tips-modal-id';
export const SCREEN_SHARE_MODAL_TITLE = 'Screen Sharing Tips';

export const SCREEN_SHARE_TIPS = [
  {
    icon: <Screen color="grey" />,
    text: 'Screen sharing is the easiest with 2 monitors'
  },
  {
    icon: <Computer color="grey" />,
    text: 'Screen sharing works best on a good computer'
  },
  {
    icon: <VisibleOff color="grey" />,
    text:
      'A small dialogue box will appear once your screen is ' +
      'shared with the options to "Stop sharing" and "Hide". ' +
      'Please click "Hide", so it does not appear in your screen recording'
  }
];

export const SCREEN_SHARE_SHOW_TIPS_LABEL = "Don't show these tips again";
