import type { Meta, StoryFn } from '@storybook/react';

import { AddFolder } from './AddFolder/AddFolder';
import { ArrowDown } from './ArrowDown/ArrowDown';
import { AspectRatio } from './AspectRatio/AspectRatio';
import { BaseIcon, IconProps } from './BaseIcon/BaseIcon';
import { Bin } from './Bin/Bin';
import { Block } from './Block/Block';
import { Blur } from './Blur/Blur';
import { Chat } from './Chat/Chat';
import { Check } from './Check/Check';
import { ChevronDown } from './ChevronDown/ChevronDown';
import { ChevronRight } from './ChevronRight/ChevronRight';
import { Close } from './Close/Close';
import { Computer } from './Computer/Computer';
import { Exit } from './Exit/Exit';
import { Help } from './Help/Help';
import { Info } from './Info/Info';
import { LoadingSpinnerIcon } from './LoadingSpinnerIcon/LoadingSpinnerIcon';
import { MicOff } from './MicOff/MicOff';
import { MicOn } from './MicOn/MicOn';
import { Mute } from './Mute/Mute';
import { Pause } from './Pause/Pause';
import { Play } from './Play/Play';
import { RaisedHandOff } from './RaisedHandOff/RaisedHandOff';
import { RaisedHandOn } from './RaisedHandOn/RaisedHandOn';
import { Recording } from './Recording/Recording';
import { Screen } from './Screen/Screen';
import { ScreenShareOff } from './ScreenShareOff/ScreenShareOff';
import { ScreenShareOn } from './ScreenShareOn/ScreenShareOn';
import { Settings } from './Settings/Settings';
import { SlightBlur } from './SlightBlur/SlightBlur';
import { StarFill } from './StarFill/StarFill';
import { StarEmpty } from './StartEmpty/StartEmpty';
import { StopRecording } from './StopRecording/StopRecording';
import { Timer } from './Timer/Timer';
import { UploadCloud } from './UploadCloud/UploadCloud';
import { Users } from './Users/Users';
import { VideoOff } from './VideoOff/VideoOff';
import { VideoOn } from './VideoOn/VideoOn';
import { VisibleOff } from './VisibleOff/VisibleOff';
import { Volume } from './Volume/Volume';

const COLOR = '#FFFFFF';

export default {
  title: 'Components/Icons',
  component: BaseIcon
} as Meta<typeof BaseIcon>;

const LoadingSpinnerIconTemplate: StoryFn<typeof LoadingSpinnerIcon> = (
  args: IconProps
) => <LoadingSpinnerIcon {...args} />;

const MicOnIconTemplate: StoryFn<typeof MicOn> = (args: IconProps) => (
  <MicOn {...args} />
);

const MicOffIconTemplate: StoryFn<typeof MicOff> = (args: IconProps) => (
  <MicOff {...args} />
);

const VideoOnIconTemplate: StoryFn<typeof VideoOn> = (args: IconProps) => (
  <VideoOn {...args} />
);

const VideoOffIconTemplate: StoryFn<typeof VideoOff> = (args: IconProps) => (
  <VideoOff {...args} />
);

const SettingsIconTemplate: StoryFn<typeof Settings> = (args: IconProps) => (
  <Settings {...args} />
);

const HelpIconTemplate: StoryFn<typeof Help> = (args: IconProps) => (
  <Help {...args} />
);

const InfoIconTemplate: StoryFn<typeof Info> = (args: IconProps) => (
  <Info {...args} />
);

const ScreenShareOnIconTemplate: StoryFn<typeof ScreenShareOn> = (
  args: IconProps
) => <ScreenShareOn {...args} />;

const ScreenShareOffIconTemplate: StoryFn<typeof ScreenShareOff> = (
  args: IconProps
) => <ScreenShareOff {...args} />;

const ArrowDownIconTemplate: StoryFn<typeof ArrowDown> = (args: IconProps) => (
  <ArrowDown {...args} />
);

const CheckIconTemplate: StoryFn<typeof Check> = (args: IconProps) => (
  <Check {...args} />
);

const AddFolderIconTemplate: StoryFn<typeof AddFolder> = (args: IconProps) => (
  <AddFolder {...args} />
);

const BinIconTemplate: StoryFn<typeof Bin> = (args: IconProps) => (
  <Bin {...args} />
);

const CloseIconTemplate: StoryFn<typeof Close> = (args: IconProps) => (
  <Close {...args} />
);

const UploadCloudIconTemplate: StoryFn<typeof UploadCloud> = (
  args: IconProps
) => <UploadCloud {...args} />;

const TimerIconTemplate: StoryFn<typeof Timer> = (args: IconProps) => (
  <Timer {...args} />
);

const ExitIconTemplate: StoryFn<typeof Exit> = (args: IconProps) => (
  <Exit {...args} />
);

const RecordingIconTemplate: StoryFn<typeof Recording> = (args: IconProps) => (
  <Recording {...args} />
);

const StopRecordingIconTemplate: StoryFn<typeof StopRecording> = (
  args: IconProps
) => <StopRecording {...args} />;

const ChevronRightIconTemplate: StoryFn<typeof ChevronRight> = (
  args: IconProps
) => <ChevronRight {...args} />;

const ChevronDownIconTemplate: StoryFn<typeof ChevronDown> = (
  args: IconProps
) => <ChevronDown {...args} />;

const StarFillIconTemplate: StoryFn<typeof StarFill> = (args: IconProps) => (
  <StarFill {...args} />
);

const StarEmptyIconTemplate: StoryFn<typeof StarEmpty> = (args: IconProps) => (
  <StarEmpty {...args} />
);

const UsersIconTemplate: StoryFn<typeof StarEmpty> = (args: IconProps) => (
  <Users {...args} />
);

const ChatIconTemplate: StoryFn<typeof Chat> = (args: IconProps) => (
  <Chat {...args} />
);

const MuteIconTemplate: StoryFn<typeof Mute> = (args: IconProps) => (
  <Mute {...args} />
);

const PauseIconTemplate: StoryFn<typeof Pause> = (args: IconProps) => (
  <Pause {...args} />
);

const PlayIconTemplate: StoryFn<typeof Play> = (args: IconProps) => (
  <Play {...args} />
);

const VolumeIconTemplate: StoryFn<typeof Volume> = (args: IconProps) => (
  <Volume {...args} />
);

const RaisedHandOnIconTemplate: StoryFn<typeof RaisedHandOn> = (
  args: IconProps
) => <RaisedHandOn {...args} />;

const RaisedHandOffIconTemplate: StoryFn<typeof RaisedHandOff> = (
  args: IconProps
) => <RaisedHandOff {...args} />;

const SlightBlurIconTemplate: StoryFn<typeof SlightBlur> = (
  args: IconProps
) => <SlightBlur {...args} />;

const BlurIconTemplate: StoryFn<typeof Blur> = (args: IconProps) => (
  <Blur {...args} />
);

const BlockIconTemplate: StoryFn<typeof Block> = (args: IconProps) => (
  <Block {...args} />
);

const AspectRatioIconTemplate: StoryFn<typeof AspectRatio> = (
  args: IconProps
) => <AspectRatio {...args} />;

const ComputerIconTemplate: StoryFn<typeof Computer> = (args: IconProps) => (
  <Computer {...args} />
);

const ScreenIconTemplate: StoryFn<typeof Screen> = (args: IconProps) => (
  <Screen {...args} />
);

const VisibleOffIconTemplate: StoryFn<typeof VisibleOff> = (
  args: IconProps
) => <VisibleOff {...args} />;

export const LoadingIcon = LoadingSpinnerIconTemplate.bind({});
export const MicOnIcon = MicOnIconTemplate.bind({});
export const MicOffIcon = MicOffIconTemplate.bind({});
export const VideoOnIcon = VideoOnIconTemplate.bind({});
export const VideoOffIcon = VideoOffIconTemplate.bind({});
export const SettingsIcon = SettingsIconTemplate.bind({});
export const HelpIcon = HelpIconTemplate.bind({});
export const InfoIcon = InfoIconTemplate.bind({});
export const ScreenShareOnInfoIcon = ScreenShareOnIconTemplate.bind({});
export const ScreenShareOffInfoIcon = ScreenShareOffIconTemplate.bind({});
export const ArrowDownIcon = ArrowDownIconTemplate.bind({});
export const CheckIcon = CheckIconTemplate.bind({});
export const AddFolderIcon = AddFolderIconTemplate.bind({});
export const BinIcon = BinIconTemplate.bind({});
export const CloseIcon = CloseIconTemplate.bind({});
export const UploadCloudIcon = UploadCloudIconTemplate.bind({});
export const TimerIcon = TimerIconTemplate.bind({});
export const ExitIcon = ExitIconTemplate.bind({});
export const RecordingIcon = RecordingIconTemplate.bind({});
export const StopRecordingIcon = StopRecordingIconTemplate.bind({});
export const ChevronRightIcon = ChevronRightIconTemplate.bind({});
export const ChevronDownIcon = ChevronDownIconTemplate.bind({});
export const StarFillIcon = StarFillIconTemplate.bind({});
export const StarEmptyIcon = StarEmptyIconTemplate.bind({});
export const UsersIcon = UsersIconTemplate.bind({});
export const ChatIcon = ChatIconTemplate.bind({});
export const MuteIcon = MuteIconTemplate.bind({});
export const PauseIcon = PauseIconTemplate.bind({});
export const PlayIcon = PlayIconTemplate.bind({});
export const VolumeIcon = VolumeIconTemplate.bind({});
export const RaisedHandOnIcon = RaisedHandOnIconTemplate.bind({});
export const RaisedHandOffIcon = RaisedHandOffIconTemplate.bind({});
export const SlightBlurIcon = SlightBlurIconTemplate.bind({});
export const BlurIcon = BlurIconTemplate.bind({});
export const BlockIcon = BlockIconTemplate.bind({});
export const AspectRatioIcon = AspectRatioIconTemplate.bind({});
export const ComputerIcon = ComputerIconTemplate.bind({});
export const ScreenIcon = ScreenIconTemplate.bind({});
export const VisibleOffIcon = VisibleOffIconTemplate.bind({});

LoadingIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

MicOnIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

MicOffIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

VideoOnIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

VideoOffIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

SettingsIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

HelpIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

InfoIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ScreenShareOnInfoIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ScreenShareOffInfoIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ArrowDownIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

CheckIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

AddFolderIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

BinIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

CloseIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

UploadCloudIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

TimerIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ExitIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

RecordingIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

StopRecordingIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ChevronRightIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ChevronDownIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

StarEmptyIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

StarFillIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

UsersIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ChatIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

MuteIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

PauseIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

PlayIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

VolumeIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

RaisedHandOnIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

RaisedHandOffIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

SlightBlurIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

BlurIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

BlockIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

AspectRatioIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ComputerIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

ScreenIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;

VisibleOffIcon.args = {
  color: COLOR,
  scale: 1
} as IconProps;
