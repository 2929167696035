import { AspectRatio } from '../constants/grid';

type LayoutType = {
  aspectRatio: AspectRatio[];
  layout: string;
};

type ScreenType = {
  noScreen: LayoutType;
  screen: LayoutType;
};

type GridLayoutType = {
  [key: number]: ScreenType;
};

export const GRID_LAYOUT: GridLayoutType = {
  1: {
    noScreen: {
      layout: `"b a a a a a c"`,
      aspectRatio: [AspectRatio['16:9']]
    },
    screen: {
      layout: `"b" "a" "a" "c"`,
      aspectRatio: [AspectRatio['16:9']]
    }
  },
  2: {
    noScreen: {
      layout: `"a b"`,
      aspectRatio: [AspectRatio['1:1'], AspectRatio['1:1']]
    },
    screen: {
      layout: `"a" "b"`,
      aspectRatio: [AspectRatio['16:9'], AspectRatio['16:9']]
    }
  },
  3: {
    noScreen: {
      layout: `"a b c"`,
      aspectRatio: [AspectRatio['3:4'], AspectRatio['3:4'], AspectRatio['3:4']]
    },
    screen: {
      layout: `"a" "b" "c"`,
      aspectRatio: [
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9']
      ]
    }
  },
  4: {
    noScreen: {
      layout: `"a b" "c d"`,
      aspectRatio: [
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9']
      ]
    },
    screen: {
      layout: `"a" "b" "c" "d"`,
      aspectRatio: [
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9']
      ]
    }
  },
  5: {
    noScreen: {
      layout: `"a a b b c c" "d d d e e e"`,
      aspectRatio: [
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['16:9'],
        AspectRatio['16:9']
      ]
    },
    screen: {
      layout: `"a b" "c d" "e e"`,
      aspectRatio: [
        AspectRatio['3:4'],
        AspectRatio['3:4'],
        AspectRatio['3:4'],
        AspectRatio['3:4'],
        AspectRatio['16:9']
      ]
    }
  },
  6: {
    noScreen: {
      layout: `"a a b b c c" "d d e e f f"`,
      aspectRatio: [
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9']
      ]
    },
    screen: {
      layout: `"a b" "c d" "e f"`,
      aspectRatio: [
        AspectRatio['3:4'],
        AspectRatio['3:4'],
        AspectRatio['3:4'],
        AspectRatio['3:4'],
        AspectRatio['3:4'],
        AspectRatio['3:4']
      ]
    }
  },
  7: {
    noScreen: {
      layout: `"a a a b b b c c c d d d" "e e e e f f f f g g g g"`,
      aspectRatio: [
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9']
      ]
    },
    screen: {
      layout: `"a b" "c d" "e f" "g g"`,
      aspectRatio: [
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['16:9']
      ]
    }
  },
  8: {
    noScreen: {
      layout: `"a a a b b b c c c d d d" "e e e f f f g g g h h h"`,
      aspectRatio: [
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3']
      ]
    },
    screen: {
      layout: `"a b" "c d" "e f" "g h"`,
      aspectRatio: [
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1']
      ]
    }
  },
  9: {
    noScreen: {
      layout: `"a b c" "d e f" "g h i"`,
      aspectRatio: [
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9'],
        AspectRatio['16:9']
      ]
    },
    screen: {
      layout: `"a b" "c d" "e f" "g h" "i i"`,
      aspectRatio: [
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['16:9']
      ]
    }
  },
  10: {
    noScreen: {
      layout: `"a b c d e" "f g h i j"`,
      aspectRatio: [
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1'],
        AspectRatio['1:1']
      ]
    },
    screen: {
      layout: `"a b" "c d" "e f" "g h" "i j"`,
      aspectRatio: [
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3'],
        AspectRatio['4:3']
      ]
    }
  }
};
