import colors from '../../../styles/colors.module.scss';

import { XMLNS } from '../../../constants/Icons';
import { BaseIcon, IconProps } from '../BaseIcon/BaseIcon';

export const StarEmpty = ({ color = colors.black, scale = 1 }: IconProps) => (
  <BaseIcon>
    <svg
      width={scale * 24}
      height={scale * 24}
      viewBox="0 -960 960 960"
      fill={color}
      xmlns={XMLNS}
    >
      <path d="m354-247 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-80l65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Zm247-350Z" />
    </svg>
  </BaseIcon>
);
