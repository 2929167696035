import { FormEvent, useEffect, useState } from 'react';

import styles from './JoinForm.module.scss';

import {
  JOIN_FORM_SINGLE_DESCRIPTION,
  joinFormSingleHeading
} from '../../../config/JoinForm';
import { MAX_NAME_LENGTH } from '../../../constants/app';
import { ButtonState, ButtonType } from '../../../constants/buttons';
import { validateEmail } from '../../../utils/validation';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { Checkbox } from '../../Inputs/Checkbox/Checkbox';
import {
  TEXT_INPUT_AUTOCOMPLETE,
  TextInput
} from '../../Inputs/TextInput/TextInput';

export const INITIAL_JOIN_FORM_DATA: JoinFormData = {
  name: '',
  email: '',
  guest: false
};

export interface JoinFormData {
  email: string;
  guest: boolean;
  name: string;
}

export interface JoinFormProps {
  eventName: string;
  group: boolean;
  onSubmitClicked: (data: JoinFormData) => Promise<void>;
}

const JOIN_FORM_ID = 'join-form';
const JOIN_FORM_NAME_ID = 'join-form-name';
const JOIN_FORM_EMAIL_ID = 'join-form-email';

export const JoinForm = ({
  eventName,
  group,
  onSubmitClicked
}: JoinFormProps) => {
  const [data, setData] = useState<JoinFormData>(INITIAL_JOIN_FORM_DATA);
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Disabled
  );

  useEffect(() => {
    if (data.name.length && validateEmail(data.email)) {
      setButtonState(ButtonState.Enabled);
    } else {
      setButtonState(ButtonState.Disabled);
    }
  }, [data]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmitClicked(data);
  };

  return (
    <section className={styles.container}>
      <hgroup className={styles.header}>
        <h1>{joinFormSingleHeading(eventName)}</h1>
        <p>{JOIN_FORM_SINGLE_DESCRIPTION}</p>
      </hgroup>

      <form id={JOIN_FORM_ID} className={styles.form} onSubmit={handleSubmit}>
        <TextInput
          id={JOIN_FORM_NAME_ID}
          name="name"
          label="Name"
          type="text"
          placeholder="Enter your name"
          maxLength={MAX_NAME_LENGTH}
          autocomplete={TEXT_INPUT_AUTOCOMPLETE.FIRST_NAME}
          value={data.name}
          onChange={(name) => setData((c) => ({ ...c, name }))}
        />
        <TextInput
          id={JOIN_FORM_EMAIL_ID}
          name="email"
          label="Email"
          type="text"
          autocomplete={TEXT_INPUT_AUTOCOMPLETE.EMAIL}
          placeholder="Enter your email address"
          value={data.email}
          onChange={(email) => setData((c) => ({ ...c, email }))}
        />
        {group && (
          <Checkbox
            label="Join as guest (Your video and audio will not be recorded)"
            checked={data.guest}
            onChange={(guest) => setData((c) => ({ ...c, guest }))}
          />
        )}
        <div className={styles.buttonWrapper}>
          <PrimaryButton
            formId={JOIN_FORM_ID}
            text="Next"
            state={buttonState}
            type={ButtonType.Submit}
          />
        </div>
      </form>
    </section>
  );
};
