import style from './Timer.module.scss';

export interface TimerProps {
  hide?: boolean;
  minutes: number;
  seconds: number;
}

export const Timer = ({ minutes, seconds, hide = false }: TimerProps) => {
  return (
    <section className={style.wrapper}>
      {!hide && (
        <>
          <span>{minutes}m</span>
          <span>{seconds}s</span>
        </>
      )}
    </section>
  );
};
