import cs from 'classnames';

import styles from './PrimaryButton.module.scss';

import { Size } from '../../../constants/Size';
import { ButtonState, ButtonType } from '../../../constants/buttons';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';

export interface PrimaryButtonProps {
  formId?: string;
  onClick?: () => void | Promise<void>;
  size?: Size;
  state?: ButtonState;
  text: string;
  type?: ButtonType;
}

export const PrimaryButton = ({
  text,
  formId,
  state = ButtonState.Enabled,
  type = ButtonType.Button,
  size = Size.Medium,
  onClick
}: PrimaryButtonProps) => (
  <button
    form={formId}
    type={type}
    className={cs({
      [styles.button]: true,
      [styles[size]]: true
    })}
    onClick={onClick}
    disabled={state === ButtonState.Loading || state === ButtonState.Disabled}
  >
    {state === ButtonState.Loading && <LoadingSpinnerIcon color="white" />}
    {text}
  </button>
);
