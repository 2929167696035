import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';

import * as packageJson from '../package.json';
import App from './App.tsx';
import { NODE_ENVS } from './constants/env.ts';
import './styles/_global.scss';

const isDevelopment = import.meta.env.MODE === NODE_ENVS.DEVELOPMENT;

Sentry.init({
  dsn: 'https://c1e089ab43634aefa96bee6e42e95583@o510792.ingest.us.sentry.io/5606973',
  debug: isDevelopment,
  release: packageJson.version,
  environment: import.meta.env.VITE_NODE_ENV,
  sampleRate: isDevelopment ? 0 : 1
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
