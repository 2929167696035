import cs from 'classnames';

import styles from './BaseImageButton.module.scss';

import { ButtonState } from '../../../constants/buttons';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';

export interface BaseImageButtonProps {
  image: string;
  onClick?: () => void | Promise<void>;
  state: ButtonState;
}

export const BaseImageButton = ({
  image,
  state,
  onClick
}: BaseImageButtonProps) => {
  const buttonClass = cs({
    [styles.button]: true,
    [styles[state]]: true,
    [styles.image]: true,
    [styles.loading]: state === ButtonState.Loading
  });

  const disabled =
    state === ButtonState.Disabled || state === ButtonState.Loading;

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
      style={{ backgroundImage: `url(${image})` }}
    >
      {state === ButtonState.Loading && <LoadingSpinnerIcon color="white" />}
    </button>
  );
};
