import { useEffect, useState } from 'react';

import { DeviceType, HMSPeer } from '@100mslive/react-sdk';

import styles from './SettingsForm.module.scss';

import Participant from '../../../components/Participant/Participant';
import { Backgrounds } from '../../../config/backgrounds';
import { Size } from '../../../constants/Size';
import { MAX_NAME_LENGTH, MIN_NAME_LENGTH } from '../../../constants/app';
import { Background } from '../../../constants/background';
import { ButtonState } from '../../../constants/buttons';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { SecondaryIconButton } from '../../IconButtons/SecondaryIconButton/SecondaryIconButton';
import { Block } from '../../Icons/Block/Block';
import { Blur } from '../../Icons/Blur/Blur';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';
import { SlightBlur } from '../../Icons/SlightBlur/SlightBlur';
import { PrimaryImageButton } from '../../ImageButtons/ImageButtons';
import { Select } from '../../Inputs/Select/Select';
import { Option } from '../../Inputs/Select/SelectMenu/SelectOption/SelectOption';
import {
  TEXT_INPUT_AUTOCOMPLETE,
  TextInput
} from '../../Inputs/TextInput/TextInput';
import { Tooltip, TooltipPosition } from '../../Tooltip/Tooltip';

export interface SettingsFormProps {
  audioInputs: Option[];
  audioOutputs: Option[];
  background: Background;
  loadingPeer: boolean;
  onBlurChanged: (selected: Background) => void;
  onDeviceChanged: (selected: Option, type: DeviceType) => void;
  onNameChanged: (name: string) => void;
  peer?: HMSPeer;
  selectedAudioInput?: Option;
  selectedAudioOutput?: Option;
  selectedVideoInput?: Option;
  videoInputs: Option[];
}

export const SettingsForm = ({
  selectedAudioInput,
  videoInputs,
  selectedVideoInput,
  audioInputs,
  selectedAudioOutput,
  audioOutputs,
  loadingPeer,
  peer,
  background,
  onDeviceChanged,
  onNameChanged,
  onBlurChanged
}: SettingsFormProps) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (peer?.name) setName(peer.name);
  }, []);

  const handleNameChanged = (name: string) => setName(name);

  const handleSaveName = () => onNameChanged(name);

  return (
    <section className={styles.wrapper}>
      {loadingPeer || !peer ? (
        <div className={styles.display}>
          <div className={styles.inner}>
            <LoadingSpinnerIcon color="white" />
          </div>
        </div>
      ) : (
        <Participant peer={peer} isPreview={true} />
      )}

      <div className={styles.container}>
        <section className={styles.name}>
          <TextInput
            id="local-peer-name"
            label="Your name"
            autocomplete={TEXT_INPUT_AUTOCOMPLETE.FIRST_NAME}
            value={name}
            type="text"
            minLength={MIN_NAME_LENGTH}
            maxLength={MAX_NAME_LENGTH}
            onChange={handleNameChanged}
          />
          <PrimaryButton
            text="Save"
            size={Size.Small}
            state={
              name.length > MIN_NAME_LENGTH
                ? ButtonState.Active
                : ButtonState.Disabled
            }
            onClick={handleSaveName}
          />
        </section>

        <section className={styles.actions}>
          <Tooltip
            text="Remove effects"
            position={TooltipPosition.NORTH}
            disabled={false}
          >
            <SecondaryIconButton
              icon={<Block />}
              state={
                background === Background.None
                  ? ButtonState.Disabled
                  : ButtonState.Enabled
              }
              onClick={() => onBlurChanged(Background.None)}
            />
          </Tooltip>

          <Tooltip
            text="Light Blur"
            position={TooltipPosition.NORTH}
            disabled={false}
          >
            <SecondaryIconButton
              icon={<SlightBlur />}
              state={
                background === Background.LightBlur
                  ? ButtonState.Active
                  : ButtonState.Enabled
              }
              onClick={() => onBlurChanged(Background.LightBlur)}
            />
          </Tooltip>

          <Tooltip
            text="Blur"
            position={TooltipPosition.NORTH}
            disabled={false}
          >
            <SecondaryIconButton
              icon={<Blur />}
              state={
                background === Background.NormalBlur
                  ? ButtonState.Active
                  : ButtonState.Enabled
              }
              onClick={() => onBlurChanged(Background.NormalBlur)}
            />
          </Tooltip>

          <PrimaryImageButton
            image={Backgrounds.Image1.thumb}
            state={
              background === Background.Image1
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => onBlurChanged(Background.Image1)}
          />

          <PrimaryImageButton
            image={Backgrounds.Image2.thumb}
            state={
              background === Background.Image2
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => onBlurChanged(Background.Image2)}
          />

          <PrimaryImageButton
            image={Backgrounds.Image3.thumb}
            state={
              background === Background.Image3
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => onBlurChanged(Background.Image3)}
          />
        </section>

        <Select
          dark={true}
          label="Select Video Input"
          onChange={(value) => onDeviceChanged(value, DeviceType.videoInput)}
          options={videoInputs}
          selected={selectedVideoInput}
          placeholder="Select a video input"
        />
        <Select
          dark={true}
          label="Select Audio Input"
          onChange={(value) => onDeviceChanged(value, DeviceType.audioInput)}
          options={audioInputs}
          selected={selectedAudioInput}
          placeholder="Select an audio input"
        />
        <Select
          dark={true}
          label="Select Audio Output"
          onChange={(value) => onDeviceChanged(value, DeviceType.audioOutput)}
          options={audioOutputs}
          selected={selectedAudioOutput}
          placeholder="Select an audio output"
        />
      </div>
    </section>
  );
};
