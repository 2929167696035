import {
  HMSPeer,
  selectConnectionQualityByPeerID,
  selectIsPeerAudioEnabled,
  selectIsPeerVideoEnabled,
  selectPeerAudioByID,
  useHMSStore
} from '@100mslive/react-sdk';

import { Roles } from '../../constants/roles';
import { hmsActions } from '../../hms';
import { useRecordingContext } from '../../providers/RecordingProvider';
import { ParticipantListItem } from '../../stories/ParticipantList/ParticipantListItem/ParticipantListItem';
import { UploadState } from '../../types/participant.type';

export interface ParticipantListItemWrapperProps {
  isHost: boolean;
  participant: HMSPeer;
  progress?: string;
  state?: UploadState;
}

export const ParticipantListItemWrapper = ({
  isHost,
  participant,
  state,
  progress
}: ParticipantListItemWrapperProps) => {
  const { isRecording } = useRecordingContext();

  const audioEnabled = useHMSStore(selectIsPeerAudioEnabled(participant.id));
  const videoEnabled = useHMSStore(selectIsPeerVideoEnabled(participant.id));
  const audioLevel = useHMSStore(selectPeerAudioByID(participant.id));
  const downlinkQuality = useHMSStore(
    selectConnectionQualityByPeerID(participant.id)
  )?.downlinkQuality;

  const handleAudioToggle = async () => {
    if (isHost) {
      try {
        await hmsActions.setRemoteTrackEnabled(
          participant.audioTrack || 'microphone',
          !audioEnabled
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleVideoToggle = async () => {
    if (isHost && participant.videoTrack) {
      try {
        await hmsActions.setRemoteTrackEnabled(
          participant.videoTrack,
          !videoEnabled
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <ParticipantListItem
      name={participant.name}
      role={participant.roleName || Roles.Participant}
      state={state}
      progress={progress}
      isHandRaised={participant.isHandRaised}
      networkLevel={downlinkQuality || 0}
      audioLevel={audioLevel}
      isAudioOn={audioEnabled}
      isVideoOn={videoEnabled}
      isHost={isHost}
      isRecording={isRecording}
      toggleAudio={handleAudioToggle}
      toggleVideo={handleVideoToggle}
    />
  );
};
