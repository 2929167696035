import { Fragment, useState } from 'react';

import cs from 'classnames';

import colors from '../../styles/colors.module.scss';
import styles from './Accordion.module.scss';

import { ChevronDown } from '../Icons/ChevronDown/ChevronDown';

export interface AccordionItemProps {
  content: string;
  title: string;
}

export interface AccordionProps {
  id: string;
  items: AccordionItemProps[];
}

const AccordionItem = ({ title, content }: AccordionItemProps) => {
  const [isActive, setIsActive] = useState(false);

  const toggleAccordion = () => {
    setIsActive((c) => !c);
  };

  const itemClass = cs({
    [styles.item]: true,
    [styles.active]: isActive
  });

  return (
    <div className={itemClass}>
      <div className={styles.header} onClick={toggleAccordion}>
        {title}
        <ChevronDown color={colors.lightGrey} scale={1.5} />
      </div>
      <div className={styles.body}>{content}</div>
    </div>
  );
};

export const Accordion = ({ id, items }: AccordionProps) => {
  return (
    <div className={styles.accordion}>
      {items.map((item, index) => (
        <Fragment key={`${id}-${index}`}>
          <AccordionItem title={item.title} content={item.content} />
        </Fragment>
      ))}
    </div>
  );
};
