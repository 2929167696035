import cs from 'classnames';

import styles from './EndSessionButton.module.scss';

import { ButtonState, ButtonType } from '../../../constants/buttons';
import { Exit } from '../../Icons/Exit/Exit';

export interface EndSessionButtonProps {
  isHost: boolean;
  onClick?: () => void | Promise<void>;
  state?: ButtonState;
  type?: ButtonType;
}

export const EndSessionButton = ({
  isHost,
  state = ButtonState.Enabled,
  type = ButtonType.Button,
  onClick
}: EndSessionButtonProps) => (
  <button
    type={type}
    className={cs({
      [styles.button]: true,
      [styles[state]]: true
    })}
    onClick={onClick}
  >
    <Exit />
    {isHost ? 'End Session' : 'Leave Session'}
  </button>
);
