import styles from './UploadProgress.module.scss';

import { LottieAnimationType } from '../../constants/LottieAnimations';
import { LottieAnimation } from '../LottieAnimation/LottieAnimation';

export interface UploadProgressProps {
  progress: number;
}

export const UploadProgress = ({ progress }: UploadProgressProps) => (
  <div className={styles.container}>
    <div className={styles.uploading}>
      <LottieAnimation
        animation={LottieAnimationType.UploadingLight}
        loop={true}
      />
    </div>
    <span>{progress.toFixed(0)}% Uploading...</span>
  </div>
);
