import { useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import styles from './EndTakeModal.module.scss';

import {
  END_TAKE_COMPLETE_DELETE_MESSAGE_1,
  END_TAKE_COMPLETE_DELETE_MESSAGE_2,
  END_TAKE_COMPLETE_SAVE_GROUP_MESSAGE_2,
  END_TAKE_COMPLETE_SAVE_MESSAGE_1,
  END_TAKE_COMPLETE_SAVE_MESSAGE_2,
  END_TAKE_MODAL_ASSETS_LABEL,
  END_TAKE_MODAL_BACK_BUTTON_LABEL,
  END_TAKE_MODAL_DELETE_BUTTON_LABEL,
  END_TAKE_MODAL_DESCRIPTION,
  END_TAKE_MODAL_DESCRIPTION_REQUIRED,
  END_TAKE_MODAL_DISMISS_BUTTON_LABEL,
  END_TAKE_MODAL_ERROR_REQUIRED_ASSETS,
  END_TAKE_MODAL_FINISH_BUTTON_LABEL,
  END_TAKE_MODAL_ID,
  END_TAKE_MODAL_NEXT_BUTTON_LABEL,
  END_TAKE_MODAL_NOTES_PLACEHOLDER,
  END_TAKE_MODAL_TITLE
} from '../../../config/EndTakeModal';
import { LottieAnimationType } from '../../../constants/LottieAnimations';
import { ButtonState } from '../../../constants/buttons';
import { ScreenBlobUrl } from '../../../types/screen.types';
import { formatSeconds } from '../../../utils/format';
import { createZip } from '../../../utils/zip';
import { DangerButton } from '../../Buttons/DangerButton/DangerButton';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../Buttons/SecondaryButton/SecondaryButton';
import { PrimaryIconButton } from '../../IconButtons/PrimaryIconButton/PrimaryIconButton';
import { Bin } from '../../Icons/Bin/Bin';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';
import { CommentBox } from '../../Inputs/CommentBox/CommentBox';
import { DragDrop } from '../../Inputs/DragDrop/DragDrop';
import { LottieAnimation } from '../../LottieAnimation/LottieAnimation';
import { Modal } from '../../Modal/Modal';
import {
  Annotation,
  VideoPlayer,
  VideoPlayerControls
} from '../../VideoPlayer/VideoPlayer';

export interface UploadTakeData {
  comments?: Annotation[];
  hasAssets: boolean;
  usedScreenShare: boolean;
  zip?: Blob;
}

interface TakeData {
  comments: Annotation[];
  files: File[];
}

const INITIAL_DATA: TakeData = {
  files: [],
  comments: []
};

export interface EndTakeModalProps {
  cameraUrl: string;
  close: () => void;
  isGroup: boolean;
  onDeleteTake: () => Promise<void>;
  onSubmitTake: (data: UploadTakeData) => Promise<void>;
  open: boolean;
  recordingTime: number;
  screenUrls: ScreenBlobUrl[];
}

export const EndTakeModal = ({
  open,
  isGroup,
  cameraUrl,
  screenUrls,
  recordingTime,
  onSubmitTake,
  onDeleteTake,
  close
}: EndTakeModalProps) => {
  const videoRef = useRef<VideoPlayerControls>(null);

  const [step, setStep] = useState(isGroup ? 1 : 0);
  const [comments, setComments] = useState<Annotation[]>([]);

  const [data, setData] = useState<TakeData>(INITIAL_DATA);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setStep(isGroup ? 1 : 0);
  }, [isGroup]);

  const onCommentsChanged = (comment: string) => {
    setComments((c) =>
      [
        ...c,
        { text: comment, timestamp: videoRef.current?.currentTime || 0 }
      ].sort((a, b) => a.timestamp - b.timestamp)
    );
  };

  const onFilesChanged = (files: File[]) => {
    setError(null);
    setData({ ...data, files });
  };

  const onNextClicked = async () => {
    if (step === 0) {
      setData({ ...data, comments });
      setStep(1);
    } else {
      await onFinishedClicked();
    }
  };

  const onFinishedClicked = async () => {
    setError(null);

    const assetsRequired = screenUrls.length > 0;
    if (assetsRequired && data.files.length === 0) {
      setError(END_TAKE_MODAL_ERROR_REQUIRED_ASSETS);

      return;
    }

    setLoading(true);

    let zippedBlob: Blob | undefined;
    if (data.files.length > 0) {
      zippedBlob = await createZip(data.files);
    }

    await onSubmitTake({
      usedScreenShare: assetsRequired,
      hasAssets: !!zippedBlob,
      zip: zippedBlob,
      comments: data.comments
    });

    setSaved(true);
    setLoading(false);

    videoRef.current?.stopVideos();
  };

  const onDeleteClicked = async () => {
    setLoading(true);

    await onDeleteTake();

    setDeleted(true);
    setLoading(false);

    videoRef.current?.stopVideos();
  };

  const handleDismissClicked = () => {
    close();
    setData(INITIAL_DATA);
    setStep(0);
    setSaved(false);
    setDeleted(false);
    setLoading(false);
    setError(null);
  };

  const onDeleteCommentClicked = (index: number) =>
    setComments((c) => c.filter((_, i) => i !== index));

  const onBackClicked = () => {
    setError(null);
    setStep(0);
  };

  const showButtons = !loading && !saved && !deleted;
  const showBackButton = showButtons && step === 1 && !isGroup;
  const showDismissButton = saved || deleted;
  const assetsRequired = screenUrls.length > 0;

  const containerClass = cs({
    [styles.container]: true,
    [styles.assets]: step === 1 || deleted || loading
  });

  return (
    <Modal
      open={open}
      title={END_TAKE_MODAL_TITLE}
      modalId={END_TAKE_MODAL_ID}
      disableClose={true}
      disableScroll={true}
      rightButtons={
        <>
          {showBackButton && (
            <SecondaryButton
              text={END_TAKE_MODAL_BACK_BUTTON_LABEL}
              state={ButtonState.Enabled}
              onClick={onBackClicked}
            />
          )}
          {showButtons && (
            <PrimaryButton
              text={
                step === 0
                  ? END_TAKE_MODAL_NEXT_BUTTON_LABEL
                  : END_TAKE_MODAL_FINISH_BUTTON_LABEL
              }
              state={ButtonState.Enabled}
              onClick={onNextClicked}
            />
          )}
          {showDismissButton && (
            <PrimaryButton
              text={END_TAKE_MODAL_DISMISS_BUTTON_LABEL}
              state={ButtonState.Enabled}
              onClick={handleDismissClicked}
            />
          )}
        </>
      }
      leftButton={
        <>
          {showButtons && step === 0 && (
            <DangerButton
              text={END_TAKE_MODAL_DELETE_BUTTON_LABEL}
              state={ButtonState.Enabled}
              onClick={onDeleteClicked}
            />
          )}
        </>
      }
    >
      <section className={containerClass}>
        {step === 0 && (
          <>
            {!deleted && !loading && (
              <section className={styles.commentContainer}>
                <div className={styles.video}>
                  <VideoPlayer
                    ref={videoRef}
                    cameraUrl={cameraUrl}
                    screenUrls={screenUrls}
                    total={recordingTime}
                    annotations={comments}
                  />

                  <CommentBox
                    placeholder={END_TAKE_MODAL_NOTES_PLACEHOLDER}
                    onSubmitComment={onCommentsChanged}
                  />
                </div>

                <div className={styles.comments}>
                  <h3>Comments</h3>
                  {comments.length > 0 ? (
                    <ul>
                      {comments.map((comment, idx) => (
                        <li key={`comment-${idx}`}>
                          <div className={styles.content}>
                            <span>{formatSeconds(comment.timestamp)}</span>
                            <p>{comment.text}</p>
                          </div>

                          <div className={styles.controls}>
                            <PrimaryIconButton
                              icon={<Bin />}
                              onClick={() => onDeleteCommentClicked(idx)}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className={styles.emptyComments}>
                      <h6>No comments</h6>
                    </div>
                  )}
                </div>
              </section>
            )}
            {deleted && (
              <div className={styles.complete}>
                <div className={styles.animation}>
                  <LottieAnimation
                    animation={LottieAnimationType.Deleted}
                    loop={false}
                  />
                </div>
                <p>{END_TAKE_COMPLETE_DELETE_MESSAGE_1}</p>
                <p>{END_TAKE_COMPLETE_DELETE_MESSAGE_2}</p>
              </div>
            )}
          </>
        )}
        {step === 1 && (
          <>
            {!saved && !loading && (
              <div className={styles.form}>
                <div>
                  <p>
                    {END_TAKE_MODAL_DESCRIPTION}
                    <b>
                      {assetsRequired
                        ? END_TAKE_MODAL_DESCRIPTION_REQUIRED
                        : ''}
                    </b>
                    .
                  </p>

                  <DragDrop
                    label={END_TAKE_MODAL_ASSETS_LABEL}
                    files={data.files}
                    onChange={onFilesChanged}
                  />
                </div>
              </div>
            )}
            {saved && !loading && (
              <div className={styles.complete}>
                <div className={styles.animation}>
                  <LottieAnimation
                    animation={LottieAnimationType.Completed}
                    loop={false}
                  />
                </div>
                <p>{END_TAKE_COMPLETE_SAVE_MESSAGE_1}</p>
                <p>
                  {isGroup
                    ? END_TAKE_COMPLETE_SAVE_GROUP_MESSAGE_2
                    : END_TAKE_COMPLETE_SAVE_MESSAGE_2}
                </p>
              </div>
            )}
          </>
        )}

        {loading && (
          <div className={styles.loading}>
            <LoadingSpinnerIcon color="white" />
          </div>
        )}

        {error && <p className={styles.error}>{error}</p>}
      </section>
    </Modal>
  );
};
