import { useEffect, useState } from 'react';

export default function useGetAudioStream(trackId?: string) {
  const [audioStream, setAudioStream] = useState<MediaStream>();

  useEffect(() => {
    async function setup() {
      if (trackId && !audioStream) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: trackId
          }
        });

        setAudioStream(stream);
      }
    }

    setup();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [trackId, audioStream]);

  return { audioStream };
}
