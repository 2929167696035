import { useCallback, useEffect, useState } from 'react';

import { getDatabase, off, onValue, ref, set } from 'firebase/database';

import { JoinStep } from '../pages/Join/Join';

export default function useHostPresent(
  step: JoinStep,
  eventId?: string,
  groupId?: string
) {
  const [hostPresent, setHostPresent] = useState(false);

  const hostPresentGroupRef = ref(
    getDatabase(),
    `groupRecordings/${eventId}/${groupId}/hostPresent`
  );

  useEffect(() => {
    if (eventId && groupId && step === JoinStep.DeviceSelection) {
      onValue(hostPresentGroupRef, (snapshot) => {
        const data = snapshot.val() as boolean;
        setHostPresent(data);
      });

      return () => off(hostPresentGroupRef);
    }
  }, [step]);

  const updateHostPresent = useCallback(
    async () => set(hostPresentGroupRef, true),
    []
  );

  return { hostPresent, updateHostPresent };
}
