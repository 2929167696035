import colors from '../../styles/colors.module.scss';
import styles from './NetworkQuality.module.scss';

export interface NetworkQualityProps {
  quality: number;
}

const STEP = 4;
const BARS_ARRAY = [0, 1, 2, 3, 4];
const COLORS = [
  colors._error,
  colors._error,
  colors._warning,
  colors._success,
  colors._success
];

export const NetworkQuality = ({ quality }: NetworkQualityProps) => (
  <section className={styles.outerContainer}>
    <div className={styles.innerContainer}>
      {BARS_ARRAY.map((level) => (
        <div
          key={level}
          style={{
            height: `${STEP * (level + 1)}px`,
            background:
              quality > level ? COLORS[quality - 1] : 'rgba(255, 255, 255, 0.2)'
          }}
        />
      ))}
    </div>
  </section>
);
