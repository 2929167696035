import colors from '../../../styles/colors.module.scss';

import { XMLNS } from '../../../constants/Icons';
import { BaseIcon, IconProps } from '../BaseIcon/BaseIcon';

export const SlightBlur = ({ color = colors.black, scale = 1 }: IconProps) => (
  <BaseIcon>
    <svg
      width={scale * 26}
      height={scale * 26}
      viewBox="0 0 24 24"
      fill={color}
      xmlns={XMLNS}
    >
      <circle cx="9.33653" cy="0.586526" r="0.586526" stroke="none"></circle>
      <circle cx="14.5094" cy="0.586526" r="0.586526" stroke="none"></circle>
      <circle cx="14.5094" cy="23.4137" r="0.586526" stroke="none"></circle>
      <circle cx="9.33653" cy="23.4137" r="0.586526" stroke="none"></circle>
      <circle cx="4.4209" cy="4.99023" r="0.75" stroke="none"></circle>
      <circle cx="9.49316" cy="4.99023" r="0.75" stroke="none"></circle>
      <circle cx="14.5664" cy="4.99023" r="0.75" stroke="none"></circle>
      <circle cx="19.7207" cy="4.99023" r="0.75" stroke="none"></circle>
      <circle cx="19.7207" cy="9.72314" r="0.75" stroke="none"></circle>
      <circle cx="19.7207" cy="14.7964" r="0.75" stroke="none"></circle>
      <circle cx="19.7207" cy="19.8687" r="0.75" stroke="none"></circle>
      <circle cx="4.41992" cy="19.8687" r="0.75" stroke="none"></circle>
      <circle cx="4.4209" cy="14.7964" r="0.75" stroke="none"></circle>
      <circle cx="4.4209" cy="9.72314" r="0.75" stroke="none"></circle>
      <circle cx="0.735391" cy="14.8081" r="0.585" stroke="none"></circle>
      <circle cx="0.735391" cy="9.7349" r="0.585" stroke="none"></circle>
      <circle cx="23.4053" cy="14.8081" r="0.585" stroke="none"></circle>
      <circle cx="23.4053" cy="9.7349" r="0.585" stroke="none"></circle>
      <path
        d="M11.889 12.6812C13.1096 12.6812 14.1083 11.6826 14.1083 10.462C14.1083 9.24135 13.1096 8.24268 11.889 8.24268C10.6684 8.24268 9.66969 9.24135 9.66969 10.462C9.66969 11.6826 10.6684 12.6812 11.889 12.6812Z"
        stroke="none"
      ></path>
      <path
        d="M16.4678 18.0999C16.4678 15.6999 13.5572 14.6631 12.0689 14.6631C10.5806 14.6631 7.66992 15.6999 7.66992 18.0999V19.4631H16.4678V18.0999Z"
        stroke="none"
      ></path>
      <path
        d="M11.889 12.6812C13.1096 12.6812 14.1083 11.6826 14.1083 10.462C14.1083 9.24135 13.1096 8.24268 11.889 8.24268C10.6684 8.24268 9.66969 9.24135 9.66969 10.462C9.66969 11.6826 10.6684 12.6812 11.889 12.6812Z"
        fill="none"
        strokeWidth="1.8"
      ></path>
      <path
        d="M16.4678 18.0999C16.4678 15.6999 13.5572 14.6631 12.0689 14.6631C10.5806 14.6631 7.66992 15.6999 7.66992 18.0999V19.4631H16.4678V18.0999Z"
        fill="none"
        strokeWidth="1.8"
      ></path>
    </svg>
  </BaseIcon>
);
