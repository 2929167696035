import { HMSPeer } from '@100mslive/react-sdk';

import styles from './ParticipantList.module.scss';

import { ParticipantListItemWrapper } from '../../components/ParticipantListItemWrapper/ParticipantListItemWrapper';
import { ButtonState } from '../../constants/buttons';
import { ParticipantDetails } from '../../types/participant.type';
import { SecondaryIconButton } from '../IconButtons/SecondaryIconButton/SecondaryIconButton';
import { Close } from '../Icons/Close/Close';

export interface ParticipantListProps {
  close: () => void;
  isHost: boolean;
  participantDetails: Record<string, ParticipantDetails>;
  participants: HMSPeer[];
}

export const ParticipantList = ({
  isHost,
  participants,
  participantDetails,
  close
}: ParticipantListProps) => (
  <section className={styles.wrapper}>
    <hgroup className={styles.heading}>
      <h3>Participants</h3>
      <SecondaryIconButton
        icon={<Close />}
        state={ButtonState.Enabled}
        onClick={close}
      />
    </hgroup>

    <div className={styles.participants}>
      {participants.map((it) => {
        const details = participantDetails[it.id];
        const show = isHost && details;

        return (
          <ParticipantListItemWrapper
            key={`participant-list-item-${it.id}`}
            isHost={isHost}
            participant={it}
            state={show ? details.state : undefined}
            progress={show ? details.progress : undefined}
          />
        );
      })}
    </div>
  </section>
);
