import { HMSMessage } from '@100mslive/react-sdk';
import cs from 'classnames';

import styles from './ChatMessage.module.scss';

export interface ChatMessageProps {
  isHost: boolean;
  isSender: boolean;
  message: HMSMessage;
}

export const ChatMessage = ({
  message,
  isSender,
  isHost
}: ChatMessageProps) => {
  const containerClass = cs({
    [styles.container]: true,
    [styles.currentUser]: isSender,
    [styles.otherUser]: !isSender
  });

  const name = isHost ? `${message.senderName} (Host)` : message.senderName;

  return (
    <section className={containerClass}>
      <div className={styles.wrapper}>
        <h5 className={styles.name}>{name}</h5>
        <p className={styles.message}>{message.message}</p>
      </div>
    </section>
  );
};
