import colors from '../../../styles/colors.module.scss';

import { XMLNS } from '../../../constants/Icons';
import { BaseIcon, IconProps } from '../BaseIcon/BaseIcon';

export const AspectRatio = ({ color = colors.black, scale = 1 }: IconProps) => (
  <BaseIcon>
    <svg
      width={scale * 24}
      height={scale * 24}
      viewBox="0 -960 960 960"
      fill={color}
      xmlns={XMLNS}
    >
      <path d="M560-280h200v-200h-80v120H560v80ZM200-480h80v-120h120v-80H200v200Zm-40 320q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z" />
    </svg>
  </BaseIcon>
);
