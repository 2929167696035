import {
  HMSPeer,
  HMSScreenVideoTrack,
  selectIsLocalScreenShared,
  selectScreenShareAudioByPeerID,
  useHMSStore
} from '@100mslive/react-sdk';

import { Roles } from '../../constants/roles';
import { LocalScreenDisplay } from '../../stories/LocalScreenDisplay/LocalScreenDisplay';

export interface LocalScreenParticipantProps {
  isRecording: boolean;
  peer: HMSPeer;
  track: HMSScreenVideoTrack;
}

export default function LocalScreenParticipant({
  isRecording,
  peer,
  track
}: LocalScreenParticipantProps) {
  const isScreenShared = useHMSStore(selectIsLocalScreenShared);
  const audioTrack = useHMSStore(selectScreenShareAudioByPeerID(peer?.id));

  return (
    <LocalScreenDisplay
      isRecording={isRecording}
      isGuest={peer?.roleName === Roles.Guest}
      videoOn={isScreenShared}
      videoTrack={track.id}
      audioTrack={audioTrack?.id}
    />
  );
}
