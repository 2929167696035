import cs from 'classnames';

import styles from './SelectMenu.module.scss';

import { Option, SelectOption } from './SelectOption/SelectOption';

export interface SelectMenuProps {
  onChange: (selected: Option) => void;
  open: boolean;
  options: Option[];
  selected?: Option;
}

export const SelectMenu = ({
  open,
  options,
  selected,
  onChange
}: SelectMenuProps) => {
  const innerClass = cs({
    [styles.inner]: true,
    [styles.hide]: !open
  });

  return (
    <div className={styles.wrapper}>
      <menu className={innerClass}>
        {options.map((option, idx) => (
          <SelectOption
            key={`${option.value}-${idx}`}
            option={option}
            selected={selected}
            onChange={onChange}
          />
        ))}
      </menu>
    </div>
  );
};
