import { useEffect } from 'react';

import {
  selectBroadcastMessages,
  selectLocalPeer,
  useHMSStore
} from '@100mslive/react-sdk';

import { hmsActions, hmsStore } from '../../hms';
import useSendMessageToFirestore from '../../hooks/useSendMessageToFirestore';
import { useAppState } from '../../providers/AppState';
import { ParticipantChat } from '../../stories/ParticipantChat/ParticipantChat';

export interface ParticipantChatWrapperProps {
  close: () => void;
  isOpen: boolean;
}

export const ParticipantChatWrapper = ({
  isOpen,
  close
}: ParticipantChatWrapperProps) => {
  const { sessionData } = useAppState();
  const { sendMessageToFirestore } = useSendMessageToFirestore();

  const localPeer = hmsStore.getState(selectLocalPeer);
  const broadcastMessages = useHMSStore(selectBroadcastMessages);

  useEffect(() => {
    if (isOpen) {
      hmsActions.setMessageRead(true);
    }
  }, [broadcastMessages, isOpen]);

  const handleSendMessage = async (message: string) => {
    if (sessionData && localPeer) {
      await hmsActions.sendBroadcastMessage(message);
      await sendMessageToFirestore(sessionData.uploadId, localPeer.id, message);
    }
  };

  return (
    <ParticipantChat
      messages={broadcastMessages}
      sendMessage={handleSendMessage}
      currentUserId={localPeer?.id}
      isOpen={isOpen}
      close={close}
    />
  );
};
