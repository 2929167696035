import { useCallback } from 'react';

import { getDatabase, ref, set } from 'firebase/database';

import { UploadState } from '../types/participant.type';

export default function useUploadProgress() {
  const initUploadProgress = useCallback(
    async (sessionId: string, participantId: string) => {
      const progressRef = ref(
        getDatabase(),
        `sessions/${sessionId}/participants/${participantId}/uploading`
      );
      await set(progressRef, {
        state: UploadState.NOT_STARTED
      });
    },
    []
  );

  const updateUploadProgress = useCallback(
    async (sessionId: string, participantId: string, progress: string) => {
      const progressRef = ref(
        getDatabase(),
        `sessions/${sessionId}/participants/${participantId}/uploading`
      );
      await set(progressRef, {
        state: UploadState.UPLOADING,
        progress
      });
    },
    []
  );

  const completeUploadProgress = useCallback(
    async (sessionId: string, participantId: string) => {
      const progressRef = ref(
        getDatabase(),
        `sessions/${sessionId}/participants/${participantId}/uploading`
      );
      await set(progressRef, {
        state: UploadState.DONE
      });
    },
    []
  );

  return { initUploadProgress, updateUploadProgress, completeUploadProgress };
}
