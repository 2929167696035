import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  checked: boolean;
  label: string;
  onChange: (value: boolean) => void;
}

export const Checkbox = ({ label, checked, onChange }: CheckboxProps) => (
  <label className={styles.container}>
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
    />
    <span className={styles.checkbox}></span>
    <span className={styles.checkboxLabel}>{label}</span>
  </label>
);
