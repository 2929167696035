import { useLottie } from 'lottie-react';

import completeAnimation from '../../assets/lottie/complete.json';
import deleteAnimation from '../../assets/lottie/delete.json';
import errorAnimation from '../../assets/lottie/error.json';
import notFoundAnimation from '../../assets/lottie/not-found.json';
import recordingDotAnimation from '../../assets/lottie/recording-dot.json';
import uploadingAnimation from '../../assets/lottie/uploading.json';
import uploadingLight from '../../assets/lottie/uploading_white.json';
import {
  LottieAnimationType,
  LottieAnimationTypeValues
} from '../../constants/LottieAnimations';

export interface LottieAnimationProps {
  animation: LottieAnimationTypeValues;
  loop?: boolean;
}

export const LottieAnimation = ({
  loop = true,
  animation
}: LottieAnimationProps) => {
  const getAnimation = (type: LottieAnimationTypeValues) => {
    switch (type) {
      case LottieAnimationType.NotFound:
        return notFoundAnimation;
      case LottieAnimationType.Error:
        return errorAnimation;
      case LottieAnimationType.RecordingDot:
        return recordingDotAnimation;
      case LottieAnimationType.Completed:
        return completeAnimation;
      case LottieAnimationType.Uploading:
        return uploadingAnimation;
      case LottieAnimationType.UploadingLight:
        return uploadingLight;
      case LottieAnimationType.Deleted:
        return deleteAnimation;
      default:
        return notFoundAnimation;
    }
  };

  const options = {
    animationData: getAnimation(animation),
    loop
  };

  const { View } = useLottie(options);

  return View;
};
