import colors from '../../../styles/colors.module.scss';

import { XMLNS } from '../../../constants/Icons';
import { BaseIcon, IconProps } from '../BaseIcon/BaseIcon';

export const Timer = ({ color = colors.black, scale = 1 }: IconProps) => (
  <BaseIcon>
    <svg
      width={scale * 24}
      height={scale * 24}
      viewBox="0 -960 960 960"
      fill={color}
      xmlns={XMLNS}
    >
      <path d="M342.477-840v-97.523h275.046V-840H342.477Zm89.044 455.827h96.958v-241.696h-96.958v241.696ZM480-45.52q-78.522 0-147.417-29.851t-120.435-81.174q-51.54-51.323-81.453-120.215-29.913-68.891-29.913-147.413t29.964-147.388q29.965-68.866 81.483-120.384 51.517-51.517 120.383-81.482Q401.478-803.391 480-803.391q63.13 0 122.391 20t109.827 58.565l62.783-62.783 69 69-62.783 62.783q38.566 50.566 58.283 109.544 19.717 58.978 19.717 122.109 0 78.522-29.913 147.413-29.913 68.892-81.453 120.215-51.54 51.323-120.435 81.174Q558.522-45.52 480-45.52Zm0-106.001q113.739 0 193.478-79.457 79.739-79.456 79.739-193.195 0-113.739-79.739-193.478T480-697.39q-113.739 0-193.478 79.739t-79.739 193.478q0 113.739 79.739 193.195Q366.261-151.521 480-151.521Zm0-272.652Z" />
    </svg>
  </BaseIcon>
);
