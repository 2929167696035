export const ENABLE_VIDEO = 'Request video on';
export const DISABLE_VIDEO = 'Disable video';
export const RECORDING_VIDEO = 'Unable to disable video while recording';

export const UNMUTE = 'Request unmute';
export const MUTE = 'Mute';

export const CAMERA_ON = 'Camera is on';
export const CAMERA_OFF = 'Camera is off';

export const MIC_ON = 'Microphone is on';
export const MIC_OFF = 'Microphone is off';

export const MICROPHONE_LEVEL = 'Microphone level';
export const NETWORK_QUALITY = (level: number) => {
  switch (level) {
    case 1:
      return 'Network quality is very poor';
    case 2:
      return 'Network quality is poor';
    case 3:
      return 'Network quality is average';
    case 4:
      return 'Network quality is good';
    case 5:
      return 'Network quality is very good';
    default:
      return 'Determining network quality...';
      break;
  }
};
