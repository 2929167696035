import { IpApiResponse } from '../types/location.type';

interface LocationResult {
  countryCode: string;
  countryName: string;
  valid: boolean;
}

const invalidCountries = ['CN', 'CU', 'IR', 'KP', 'MM', 'SD', 'SY'];

/**
 * It returns a promise that resolves to an object with three properties: valid, countryName, and
 * countryCode
 * @returns An object with the following properties:
 *     valid: boolean
 *     countryName: string
 *     countryCode: string
 */
export const getLocationFromIp = async (): Promise<LocationResult> => {
  try {
    const result = await fetch('https://ipapi.co/json');
    const data = (await result.json()) as IpApiResponse;

    return {
      countryCode: data.country_code,
      countryName: data.country_name,
      valid: !invalidCountries.includes(data.country_code)
    };
  } catch (error) {
    console.error(error);
  }

  return { valid: true, countryName: '', countryCode: '' };
};
