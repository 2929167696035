import cs from 'classnames';

import styles from './AudioLevel.module.scss';

import { MicOff } from '../Icons/MicOff/MicOff';
import { MicOn } from '../Icons/MicOn/MicOn';

let clipId = 0;
const getUniqueClipId = () => clipId++;

export interface AudioLevelProps {
  audioLevel: number;
  audioOn: boolean;
  color?: string;
}

export const AudioLevel = ({
  audioOn,
  audioLevel,
  color = 'white'
}: AudioLevelProps) => {
  const clipPathId = `audio-level-clip-${getUniqueClipId()}`;

  const barClass = cs({
    [styles.bar]: true,
    [styles.low]: audioLevel <= 65,
    [styles.medium]: audioLevel > 65 && audioLevel < 90,
    [styles.high]: audioLevel >= 90
  });

  console.log('audioLevel', audioLevel);

  return (
    <section
      id={clipPathId}
      className={styles.container}
      data-test-audio-indicator
    >
      {audioOn && (
        <div className={styles.levelContainer}>
          <span
            className={barClass}
            style={{ height: `${audioLevel}%` }}
          ></span>
        </div>
      )}
      {audioOn ? (
        <MicOn color={color} scale={16 / 24} />
      ) : (
        <MicOff color={color} scale={16 / 24} />
      )}
    </section>
  );
};
