export const CONFIRM_MODAL_ID = 'confirm-modal-id';

export const CONFIRM_MODAL_CONFIRM = 'Confirm';
export const CONFIRM_MODAL_CANCEL = 'Cancel';

export const CONFIRM_END_SESSION_TITLE = 'End Session';
export const CONFIRM_END_SESSION_MESSAGE =
  'Are you sure you want to end the session?';

export const CONFIRM_LEAVE_SESSION_TITLE = 'Leave Session';
export const CONFIRM_LEAVE_SESSION_MESSAGE =
  'Are you sure you want to leave the session?';
