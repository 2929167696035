import { useCallback } from 'react';

import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp
} from 'firebase/firestore';

export default function useSendMessageToFirestore() {
  const sendMessageToFirestore = useCallback(
    async (sessionId: string, userId: string, message: string) => {
      await addDoc(collection(getFirestore(), `uploads/${sessionId}/chat`), {
        userId,
        message,
        timestamp: serverTimestamp()
      });
    },
    []
  );

  return { sendMessageToFirestore };
}
