import { ReactNode } from 'react';

import styles from './Card.module.scss';

export interface CardProps {
  children: ReactNode;
}

export const Card = ({ children }: CardProps) => (
  <section className={styles.cardWrapper}>{children}</section>
);
