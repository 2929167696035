import { CookieSetOptions } from 'universal-cookie';

const options: CookieSetOptions = {
  path: '/',
  secure: true,
  sameSite: 'strict'
};

export const COOKIE_CONFIG = {
  EVENT_URL: {
    NAME: 'event_url',
    OPTIONS: options
  }
};

export const SCREEN_SHARE_TIPS_COOKIE = {
  NAME: 'screen_share_tips',
  OPTIONS: options
};
