import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  DeviceType,
  selectLocalPeer,
  useDevices,
  useHMSActions,
  useHMSStore
} from '@100mslive/react-sdk';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import md5 from 'md5';
import Cookies from 'universal-cookie';

import styles from './Join.module.scss';

import { HOST_HAS_JOINED } from '../../config/JoinForm';
import { COOKIE_CONFIG } from '../../config/cookies';
import { Size } from '../../constants/Size';
import { APP_TITLE } from '../../constants/app';
import { ButtonState } from '../../constants/buttons';
import { Roles } from '../../constants/roles';
import useHostPresent from '../../hooks/useHostPresent';
import { useAppState } from '../../providers/AppState';
import { Card } from '../../stories/Card/Card';
import { DeviceSelectForm } from '../../stories/Forms/DeviceSelectForm/DeviceSelectForm';
import { JoinForm, JoinFormData } from '../../stories/Forms/JoinForm/JoinForm';
import { PrimaryIconButton } from '../../stories/IconButtons/PrimaryIconButton/PrimaryIconButton';
import { Help } from '../../stories/Icons/Help/Help';
import { LoadingSpinnerIcon } from '../../stories/Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';
import { Option } from '../../stories/Inputs/Select/SelectMenu/SelectOption/SelectOption';
import { LogoWithText } from '../../stories/Logo/Logo';
import { HelpModal } from '../../stories/Modals/HelpModal/HelpModal';
import { Toast, ToastType } from '../../stories/Toast/Toast';
import { Tooltip, TooltipPosition } from '../../stories/Tooltip/Tooltip';
import { buildDeviceOptions } from '../../utils/devices';

const HOST_JOINED_TOAST_ID = 'host-joined';

export enum JoinStep {
  Joined,
  Details,
  DeviceSelection
}

export interface CreateTokenResponse {
  roomId: string;
  token: string;
  uploadId: string;
}

export interface JoinPageProps {
  group?: boolean;
}

export const JoinPage = ({ group = false }: JoinPageProps) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<string>();
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [findingLocalUser, setFindingLocalUser] = useState(true);
  const [step, setStep] = useState(JoinStep.Details);
  const [allowedToJoin, setAllowedToJoin] = useState(false);

  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const cookies = new Cookies(null, { path: '/' });

  const {
    eventId,
    eventName,
    groupId,
    joinData,
    setJoinData,
    setSessionData,
    sessionData
  } = useAppState();
  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
  const localPeer = useHMSStore(selectLocalPeer);
  const hmsActions = useHMSActions();
  const { hostPresent } = useHostPresent(step, eventId, groupId);

  useEffect(() => {
    cookies.remove(COOKIE_CONFIG.EVENT_URL.NAME);
  }, []);

  useEffect(() => {
    if (localPeer) {
      setFindingLocalUser(false);
    }
  }, [localPeer]);

  useEffect(() => {
    if (localPeer?.roleName !== Roles.Host) {
      if (group && hostPresent) {
        toast(<Toast title={HOST_HAS_JOINED} type={ToastType.Info} />, {
          autoClose: false,
          toastId: HOST_JOINED_TOAST_ID
        });
        setAllowedToJoin(true);
      } else setAllowedToJoin(false);
    } else {
      setAllowedToJoin(true);
    }
  }, [hostPresent, localPeer, group]);

  const handleJoinSubmit = async (data: JoinFormData): Promise<void> => {
    setLoading('Getting session details...');

    const getToken = httpsCallable(getFunctions(), 'createToken', {
      limitedUseAppCheckTokens: true
    });
    const response = await getToken({
      eventId,
      groupId,
      userId: md5(data.email),
      guest: data.guest
    });
    const createdTokenResponse = response.data as CreateTokenResponse;

    setJoinData(data);
    setSessionData(createdTokenResponse);

    await setup(
      data.name,
      createdTokenResponse.token,
      createdTokenResponse.uploadId
    );
    setStep(JoinStep.DeviceSelection);

    setLoading(undefined);
  };

  const handleStartSubmit = async () => {
    if (!allowedToJoin) {
      return;
    }

    const uploadId = sessionData?.uploadId;
    if (uploadId) {
      toast.dismiss(HOST_JOINED_TOAST_ID);

      setLoading('Setting up your session...');

      // TODO: move to Firebase services
      if (localPeer) {
        await setDoc(
          doc(
            getFirestore(),
            `uploads/${uploadId}/participants/${localPeer.id}`
          ),
          {
            participantId: localPeer?.id,
            name: joinData?.name,
            email: joinData?.email,
            session: 1
          }
        );
      }

      cookies.set(
        COOKIE_CONFIG.EVENT_URL.NAME,
        window.location.pathname,
        COOKIE_CONFIG.EVENT_URL.OPTIONS
      );
      navigate(`/session/${uploadId}`);
    }
  };

  const setup = async (name: string, token: string, sessionId: string) => {
    setLoadingDevices(true);

    const config = {
      userName: name,
      authToken: token,
      settings: {
        isAudioMuted: false,
        isVideoMuted: false
      },
      metaData: JSON.stringify({
        sessionId
      }),
      rememberDeviceSelection: true,
      captureNetworkQualityInPreview: true
    };

    await hmsActions.preview(config);

    setLoadingDevices(false);
  };

  const videoInputOptions = useMemo(
    () => buildDeviceOptions(allDevices.videoInput),
    [allDevices]
  );
  const audioInputOptions = useMemo(
    () => buildDeviceOptions(allDevices.audioInput),
    [allDevices]
  );
  const audioOutputOptions = useMemo(
    () => buildDeviceOptions(allDevices.audioOutput),
    [allDevices]
  );

  const selectedVideoInput = useMemo(
    () =>
      videoInputOptions.find((it) => it.value === selectedDeviceIDs.videoInput),
    [videoInputOptions, selectedDeviceIDs]
  );

  const selectedAudioInput = useMemo(
    () =>
      audioInputOptions.find((it) => it.value === selectedDeviceIDs.audioInput),
    [audioInputOptions, selectedDeviceIDs]
  );

  const selectedAudioOutput = useMemo(
    () =>
      audioOutputOptions.find(
        (it) => it.value === selectedDeviceIDs.audioOutput
      ),
    [audioOutputOptions, selectedDeviceIDs]
  );

  const handleDeviceChanged = (selected: Option, type: DeviceType) => {
    updateDevice({
      deviceId: selected.value,
      deviceType: type
    });
  };

  if (loading) {
    return (
      <section className={styles.wrapper}>
        <Helmet>{APP_TITLE}</Helmet>
        <div className={styles.loadingWrapper}>
          <div className={styles.loader}>
            <LoadingSpinnerIcon color="white" size={Size.Large} />
          </div>
          <h1>{loading}</h1>
        </div>
      </section>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {APP_TITLE} | {eventName}
        </title>
      </Helmet>
      <div className={styles.container}>
        <section className={styles.form}>
          <Card>
            {step === JoinStep.Details && (
              <JoinForm
                eventName={eventName!}
                onSubmitClicked={handleJoinSubmit}
                group={!!group}
              />
            )}

            {!loadingDevices && step === JoinStep.DeviceSelection && (
              <DeviceSelectForm
                disableJoin={group && !allowedToJoin}
                selectedVideoInput={selectedVideoInput}
                videoInputs={videoInputOptions}
                selectedAudioInput={selectedAudioInput}
                audioInputs={audioInputOptions}
                selectedAudioOutput={selectedAudioOutput}
                audioOutputs={audioOutputOptions}
                loadingPeer={findingLocalUser}
                peer={localPeer}
                onStartClicked={handleStartSubmit}
                onDeviceChanged={handleDeviceChanged}
              />
            )}
          </Card>
        </section>

        <div className={styles.logoWrapper}>
          <LogoWithText color="white" scale={0.025} />
        </div>

        <div className={styles.helpWrapper}>
          <Tooltip
            text="Help"
            position={TooltipPosition.SOUTH}
            disabled={false}
          >
            <PrimaryIconButton
              icon={<Help color="white" />}
              state={ButtonState.Enabled}
              onClick={() => setHelpModalOpen(true)}
            />
          </Tooltip>
        </div>
      </div>

      <HelpModal
        open={helpModalOpen}
        onCloseClicked={() => setHelpModalOpen(false)}
      />
    </>
  );
};
