import { useEffect, useState } from 'react';

import styles from './CountDownTimer.module.scss';

import { ButtonState } from '../../constants/buttons';
import { SecondaryIconButton } from '../IconButtons/SecondaryIconButton/SecondaryIconButton';
import { Close } from '../Icons/Close/Close';

export interface CountDownTimerProps {
  close: () => void;
  complete: () => void;
  isHost: boolean;
}

export const CountDownTimer = ({
  isHost,
  close,
  complete
}: CountDownTimerProps) => {
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((c) => c - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countDown <= 0) complete();
  }, [countDown]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.countDown}>
        <h4>Starting in {countDown}...</h4>
        {isHost && (
          <SecondaryIconButton
            icon={<Close />}
            state={ButtonState.Active}
            onClick={close}
          />
        )}
      </div>
    </section>
  );
};
