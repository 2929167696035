import { useEffect, useState } from 'react';

import { selectIsLocalVideoEnabled, useHMSStore } from '@100mslive/react-sdk';

import { CHUNK_LENGTH } from '../config/recording';
import { useRecordingContext } from '../providers/RecordingProvider';

export default function useCameraMediaRecorder() {
  const isCameraEnabled = useHMSStore(selectIsLocalVideoEnabled);

  const { isRecording, uploadChunk, storeCameraChunk } = useRecordingContext();
  const [cameraMediaRecorder, setCameraMediaRecorder] =
    useState<MediaRecorder | null>(null);

  const createCameraMediaRecorder = (ref: MediaRecorder) => {
    if (!ref && cameraMediaRecorder?.state === 'recording') {
      cameraMediaRecorder.stop();
    }

    setCameraMediaRecorder(ref);
  };

  const stopCameraMediaRecorder = () => {
    if (cameraMediaRecorder) {
      cameraMediaRecorder.stop();
    }
  };

  useEffect(() => {
    if (cameraMediaRecorder) {
      // Handle dataavailable event
      cameraMediaRecorder.ondataavailable = (event) => {
        uploadChunk(event.data);
        storeCameraChunk(event.data);
      };

      // Handle stop event
      cameraMediaRecorder.onstop = () => {
        if (!isCameraEnabled) setCameraMediaRecorder(null);
      };

      cameraMediaRecorder.onstart = () => {};

      cameraMediaRecorder.onerror = () => {};
    }
  }, [cameraMediaRecorder]);

  useEffect(() => {
    if (cameraMediaRecorder) {
      if (isRecording && cameraMediaRecorder.state === 'inactive') {
        cameraMediaRecorder.start(CHUNK_LENGTH);
      } else if (!isRecording && cameraMediaRecorder.state === 'recording') {
        cameraMediaRecorder.stop();
      }
    }
  }, [isRecording, cameraMediaRecorder]);

  return {
    createCameraMediaRecorder,
    stopCameraMediaRecorder,
    cameraMediaRecorder
  };
}
