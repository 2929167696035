import React, { createContext, useContext, useState } from 'react';

import { CreateTokenResponse } from '../pages/Join/Join';
import { JoinFormData } from '../stories/Forms/JoinForm/JoinForm';

export interface StateContextType {
  clearError: () => void;
  clearLoading: () => void;
  error?: string;
  eventId?: string;
  eventName?: string;
  groupId?: string;
  isGroup: boolean;
  joinData?: JoinFormData;
  loading?: string;
  sessionData?: CreateTokenResponse;
  setError: (error: string) => void;
  setEventId: (eventId: string) => void;
  setEventName: (eventName: string) => void;
  setGroupId: (groupId: string) => void;
  setJoinData: (data: JoinFormData) => void;
  setLoading: (message: string) => void;
  setSessionData: (data: CreateTokenResponse) => void;
  setTopic: (topic: string) => void;
  topic?: string;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren) {
  const [loading, setLoadingMessage] = useState<string | undefined>('Loading');
  const [error, setErrorMessage] = useState<string>();
  const [eventId, setEventId] = useState<string>();
  const [eventName, setEventName] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [topic, setTopic] = useState<string>();
  const [joinData, setJoinData] = useState<JoinFormData>();
  const [sessionData, setSessionData] = useState<CreateTokenResponse>();

  const setLoading = (message: string) => {
    setLoadingMessage(message);
  };

  const setError = (message: string) => {
    setErrorMessage(message);
    clearLoading();
  };

  const clearError = () => {
    setErrorMessage(undefined);
  };

  const clearLoading = () => {
    setLoadingMessage(undefined);
  };

  return (
    <StateContext.Provider
      value={{
        isGroup: !!groupId,
        loading,
        setLoading,
        clearLoading,
        error,
        setError,
        clearError,
        eventId,
        setEventId,
        eventName,
        setEventName,
        groupId,
        setGroupId,
        topic,
        setTopic,
        joinData,
        setJoinData,
        sessionData,
        setSessionData
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }

  return context;
}
