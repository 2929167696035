import {
  BaseImageButton,
  BaseImageButtonProps
} from './BaseImageButton/BaseImageButton';

export interface ImageButtonProps extends Omit<BaseImageButtonProps, 'style'> {
  fill?: boolean;
}

export const PrimaryImageButton = ({
  image,
  state,
  onClick
}: ImageButtonProps) => (
  <BaseImageButton image={image} state={state} onClick={onClick} />
);
