import cs from 'classnames';

import colors from '../../../styles/colors.module.scss';
import styles from './LoadingSpinnerIcon.module.scss';

import { Size } from '../../../constants/Size';
import { BaseIcon, IconProps } from '../BaseIcon/BaseIcon';

const SIZE = 40;

export const LoadingSpinnerIcon = ({
  color = colors.white,
  size = Size.Medium
}: IconProps) => {
  const spinnerClass = cs({
    [styles.spinner]: true,
    [styles[size]]: true
  });

  return (
    <BaseIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        className={spinnerClass}
        stroke={color}
      >
        <circle cx={`${SIZE / 2}px`} cy={`${SIZE / 2}px`} r={`${SIZE / 3}px`} />
      </svg>
    </BaseIcon>
  );
};
