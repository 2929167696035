import style from './Take.module.scss';

export interface TakeProps {
  take: number;
}

export const Take = ({ take }: TakeProps) => {
  return (
    <section className={style.wrapper}>
      {take > 0 && <span>| Take {take}</span>}
    </section>
  );
};
