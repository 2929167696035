import { useCallback } from 'react';

import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp
} from 'firebase/firestore';

import { TIME_CODE } from '../constants/timeCode';

export default function useCreateTimeCode() {
  const addTimeCode = useCallback(
    async (
      sessionId: string,
      userId: string,
      event: TIME_CODE,
      take: number
    ) => {
      await addDoc(
        collection(getFirestore(), `uploads/${sessionId}/timeCodes`),
        {
          user: userId,
          event,
          timestamp: serverTimestamp(),
          ...(take && { take })
        }
      );
    },
    []
  );

  return { addTimeCode };
}
