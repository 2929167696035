import styles from './TextArea.module.scss';

export interface TextAreaProps {
  label: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
}

export const TextArea = ({
  label,
  placeholder,
  value,
  onChange
}: TextAreaProps) => (
  <div className={styles.wrapper}>
    <label htmlFor="textarea" className={styles.label}>
      {label}
    </label>
    <textarea
      id="textarea"
      placeholder={placeholder}
      value={value}
      className={styles.input}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
