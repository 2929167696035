import { ReactNode } from 'react';

import styles from './Header.module.scss';

export interface HeaderProps {
  center?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}

export const Header = ({ left, center, right }: HeaderProps) => (
  <header className={styles.header}>
    <div className={styles.container}>
      {left && <div className={styles.left}>{left}</div>}

      {center && <div className={styles.center}>{center}</div>}

      {right && <div className={styles.right}>{right}</div>}
    </div>
  </header>
);
