import {
  Timestamp,
  addDoc,
  collection,
  getFirestore
} from 'firebase/firestore';

import { ReviewFormData } from '../stories/Forms/ReviewForm/ReviewForm';

export const submitReview = async (
  sessionId: string,
  review: ReviewFormData
) => {
  await addDoc(collection(getFirestore(), 'reviews'), {
    sessionId,
    created: Timestamp.now(),
    ...review
  });
};
