import { useEffect } from 'react';

import {
  HMSPeer,
  selectConnectionQualityByPeerID,
  selectDominantSpeaker,
  selectIsPeerAudioEnabled,
  selectIsPeerVideoEnabled,
  selectPeerAudioByID,
  useHMSStore,
  useVideo
} from '@100mslive/react-sdk';

import { Roles } from '../../constants/roles';
import { ParticipantDisplay } from '../../stories/ParticipantDisplay/ParticipantDisplay';

export interface ParticipantProps {
  isPreview?: boolean;
  isRecording?: boolean;
  onVideoDisabled?: (id: string) => void;
  onVideoEnabled?: (id: string) => void;
  peer: HMSPeer;
}

export default function Participant({
  peer,
  isPreview = false,
  isRecording = false,
  onVideoEnabled,
  onVideoDisabled
}: ParticipantProps) {
  const { videoRef } = useVideo({
    trackId: peer.videoTrack
  });
  const audioEnabled = useHMSStore(selectIsPeerAudioEnabled(peer.id));
  const videoEnabled = useHMSStore(selectIsPeerVideoEnabled(peer.id));
  const peerAudioLevel = useHMSStore(selectPeerAudioByID(peer.id));
  const downlinkQuality = useHMSStore(
    selectConnectionQualityByPeerID(peer?.id)
  )?.downlinkQuality;
  const isLocal = peer.isLocal;
  const isSpeaking = useHMSStore(selectDominantSpeaker)?.id === peer.id;

  useEffect(() => {
    if (videoEnabled && onVideoEnabled) onVideoEnabled(peer.id);
    else if (!videoEnabled && onVideoDisabled) onVideoDisabled(peer.id);
  }, [videoEnabled]);

  return (
    <ParticipantDisplay
      isGrid={!isPreview}
      participantName={peer.name}
      audioOn={audioEnabled}
      videoOn={videoEnabled}
      audioLevel={peerAudioLevel}
      networkQuality={downlinkQuality || 0}
      isLocal={isLocal}
      isHost={peer.roleName === Roles.Host}
      isGuest={peer.roleName === Roles.Guest}
      isSpeaking={isSpeaking && !isPreview}
      isHandRaised={peer.isHandRaised}
      videoRef={videoRef}
      isRecording={isRecording} // Don't show on other participants
    />
  );
}
