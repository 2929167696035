import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

import {
  HMSMediaStreamPlugin,
  selectEffectsKey,
  selectLocalVideoTrackID,
  useHMSActions,
  useHMSStore
} from '@100mslive/react-sdk';

import { VirtualBackgroundHandler } from '../config/VirtualBackground';
import { Backgrounds, LIGHT_BLUR, NORMAL_BLUR } from '../config/backgrounds';
import { Background } from '../constants/background';
import useS3AssetUploader from '../hooks/useS3AssetsUploader';
import useS3ChunkUploader from '../hooks/useS3ChunkUploader';
import { ScreenBlobUrl } from '../types/screen.types';

export interface RecordingContextType {
  addVideoEnabled: (id: string) => void;
  assetsProgress: number;
  assetsProgressText: string;
  background: Background;
  cameraBlobUrl?: string;
  cameraChunkNumber: number;
  isAssetsComplete: boolean;
  isComplete: boolean;
  isRecording: boolean;
  isUploading: boolean;
  isUploadingAssets: boolean;
  progress: number;
  progressText: string;
  recordingTime: number;
  removeVideoEnabled: (id: string) => void;
  screenBlobUrls: ScreenBlobUrl[];
  session: number;
  setBackground: (background: Background) => void;
  startScreenRecording: () => void;
  storeCameraChunk: (blob: Blob) => void;
  storeScreenChunk: (blob: Blob) => void;
  take: number;
  toggleRecording: () => void;
  updateRecordingTime: (time: number) => void;
  updateSession: () => void;
  updateTake: () => void;
  uploadAssets: (blob: Blob) => Promise<void>;
  uploadChunk: (blob: Blob) => void;
  uploadScreen: (blob: Blob) => void;
  videosEnabled: Set<string>;
}

export const RecordingContext = createContext<RecordingContextType>(null!);

export default function RecordingProvider(props: PropsWithChildren) {
  // For Effects SDK
  const hmsActions = useHMSActions();
  const effectsKey = useHMSStore(selectEffectsKey);
  const trackId = useHMSStore(selectLocalVideoTrackID);
  const [isPluginAdded, setPluginAdded] = useState(false);
  const [background, setBackgroundName] = useState(Background.None);

  const [cameraChunkNumber, setCameraChunkNumber] = useState(1);
  const [screenChunkNumber, setScreenChunkNumber] = useState(1);
  const [session, setSession] = useState(1);
  const [take, setTake] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [screenStartTime, setScreenStartTime] = useState(0);
  const [cameraChunks, setCameraChunks] = useState<Blob[]>([]);
  const [screenChunks, setScreenChunks] = useState<Blob[]>([]);
  const [cameraBlobUrl, setCameraBlobUrl] = useState<string>();
  const [screenBlobUrls, setScreenBlobUrls] = useState<ScreenBlobUrl[]>([]);

  const [videosEnabled, setVideosEnabled] = useState(new Set<string>());

  const {
    isUploading,
    isComplete,
    progress,
    progressText,
    uploadChunk,
    uploadScreen
  } = useS3ChunkUploader({
    isRecording,
    session,
    take,
    cameraChunkNumber,
    incrementCameraChunkNumber: () => setCameraChunkNumber((c) => c + 1),
    screenChunkNumber,
    incrementScreenChunkNumber: () => setScreenChunkNumber((c) => c + 1),
    resetChunkNumber: () => {
      setCameraChunkNumber(1);
      setScreenChunkNumber(1);
    }
  });

  const {
    isUploading: isUploadingAssets,
    isComplete: isAssetsComplete,
    progress: assetsProgress,
    progressText: assetsProgressText,
    uploadAssets
  } = useS3AssetUploader({ session, take });

  const updateSession = () => setSession((c) => c + 1);

  const updateTake = () => setTake((c) => c + 1);

  const toggleRecording = () => setIsRecording((c) => !c);

  const storeCameraChunk = (blob: Blob) => setCameraChunks((c) => [...c, blob]);

  const storeScreenChunk = (blob: Blob) => setScreenChunks((c) => [...c, blob]);

  const updateRecordingTime = (time: number) => setRecordingTime(time);

  const startScreenRecording = () => setScreenStartTime(recordingTime);

  const setBackground = (background: Background) => {
    try {
      switch (background) {
        case Background.LightBlur:
          VirtualBackgroundHandler.setBlur(LIGHT_BLUR);
          break;
        case Background.NormalBlur:
          VirtualBackgroundHandler.setBlur(NORMAL_BLUR);
          break;
        case Background.Image1:
          VirtualBackgroundHandler.setBackground(Backgrounds.Image1.url);
          break;
        case Background.Image2:
          VirtualBackgroundHandler.setBackground(Backgrounds.Image2.url);
          break;
        case Background.Image3:
          VirtualBackgroundHandler.setBackground(Backgrounds.Image3.url);
          break;
        case Background.None:
          VirtualBackgroundHandler.removeEffects();
          break;
      }

      setBackgroundName(background);
    } catch (err) {
      console.error('Error adding background', err);
    }
  };

  useEffect(() => {
    if (!trackId) {
      return;
    }
    if (!isPluginAdded) {
      let vbObject = VirtualBackgroundHandler.getVBObject();

      if (!vbObject) {
        VirtualBackgroundHandler.initialisePlugin(effectsKey);
        vbObject = VirtualBackgroundHandler.getVBObject();

        if (effectsKey) {
          try {
            hmsActions.addPluginsToVideoStream([
              vbObject as HMSMediaStreamPlugin
            ]);
            setPluginAdded(true);
            console.log('Plugin added');
          } catch (error) {
            console.log('Error adding plugin', error);
            console.error('Error adding plugin', error);
          }
        }
      }
    }
  }, [hmsActions, isPluginAdded, effectsKey, trackId]);

  useEffect(() => {
    if (isRecording) {
      setCameraChunks([]);
      setCameraBlobUrl(undefined);
      setScreenBlobUrls([]);
    }
  }, [isRecording]);

  useEffect(() => {
    if (cameraChunks.length > 0) {
      const cameraBlob = new Blob(cameraChunks, { type: 'video/webm' });
      const cameraUrl = URL.createObjectURL(cameraBlob);
      setCameraBlobUrl(cameraUrl);
    }
  }, [cameraChunks]);

  useEffect(() => {
    if (screenChunks.length > 0) {
      const latestChunk = screenChunks[screenChunks.length - 1];
      const newChunk = {
        start: screenStartTime,
        stop: recordingTime,
        url: URL.createObjectURL(
          new Blob([latestChunk], { type: 'video/webm' })
        )
      };
      setScreenBlobUrls((c) => [...c, newChunk]);
    }
  }, [screenChunks]);

  const addVideoEnabled = (id: string) =>
    setVideosEnabled((c) => new Set(c).add(id));

  const removeVideoEnabled = (id: string) => {
    const newSet = new Set(videosEnabled);
    newSet.delete(id);
    setVideosEnabled(newSet);
  };

  return (
    <RecordingContext.Provider
      value={{
        cameraChunkNumber,
        session,
        take,
        recordingTime,
        isRecording,
        isUploading,
        isComplete,
        progress,
        progressText,
        isUploadingAssets,
        isAssetsComplete,
        background,
        assetsProgress,
        assetsProgressText,
        cameraBlobUrl,
        screenBlobUrls,
        updateSession,
        updateTake,
        toggleRecording,
        uploadChunk,
        uploadScreen,
        uploadAssets,
        storeCameraChunk,
        storeScreenChunk,
        updateRecordingTime,
        startScreenRecording,
        setBackground,
        videosEnabled,
        addVideoEnabled,
        removeVideoEnabled
      }}
    >
      {props.children}
    </RecordingContext.Provider>
  );
}

export function useRecordingContext() {
  const context = useContext(RecordingContext);
  if (!context) {
    throw new Error(
      'useRecordingContext must be used within the RecordingContextProvider'
    );
  }

  return context;
}
