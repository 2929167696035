import { PropsWithChildren, useEffect } from 'react';
import { isBrowser, isChrome } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { getAuth, signInAnonymously } from 'firebase/auth';
import { Timestamp, doc, getDoc, getFirestore } from 'firebase/firestore';
import md5 from 'md5';

import styles from './EventValidation.module.scss';

import { Size } from '../../constants/Size';
import { APP_TITLE } from '../../constants/app';
import { useAppState } from '../../providers/AppState';
import { getLocationFromIp } from '../../services/location';
import { LoadingSpinnerIcon } from '../../stories/Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';

export function urlSafeName(text: string): string {
  return text.replace(/[`~!@#$£%^&*()_]/g, '').toLowerCase();
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface BackgroundProps extends PropsWithChildren {
  group?: boolean;
}

export const EventValidation = ({ group, children }: BackgroundProps) => {
  const {
    loading,
    setLoading,
    clearLoading,
    setError,
    clearError,
    setEventId,
    setEventName,
    setGroupId,
    setTopic
  } = useAppState();
  const params = useParams<{
    company: string;
    event: string;
    topic?: string;
  }>();

  useEffect(() => {
    setLoading('Checking your browser...');
  }, []);

  useEffect(() => {
    async function validate() {
      clearError();

      // Check that it's a desktop browser
      if (!isBrowser) {
        setError('Cloud Present only supports desktop browsers');

        return;
      }

      // Check that it's Chrome
      if (!isChrome) {
        setError('Cloud Present only supports Google Chrome');

        return;
      }

      // Check country
      const { valid, countryName } = await getLocationFromIp();
      if (!valid) {
        setError(`Cloud Present is not available in ${countryName}`);

        return;
      }

      // Sign user into Firebase
      await signInAnonymously(getAuth());

      await delay(1000);
      setLoading('Checking event...');
      await delay(1000);

      const { company, event, topic } = params;
      if (!company || !event) {
        setError('Invalid URL');

        return;
      }

      // Get the Firebase event
      const eventId = md5(`/${urlSafeName(company)}/${urlSafeName(event)}`);
      const eventDoc = await getDoc(doc(getFirestore(), 'events', eventId));
      setEventId(eventId);

      // Check if the event exists
      if (!eventDoc.exists()) {
        setError('Invalid URL');

        return;
      }

      // Check if the event has expired
      const expires = eventDoc.get('expires') as Timestamp;
      if (expires.toDate().getTime() < new Date().getTime()) {
        setError(`${eventDoc.get('name')} has expired`);

        return;
      }

      if (group && topic) {
        const groupId = md5(
          `/${urlSafeName(company)}/${urlSafeName(event)}/${urlSafeName(topic)}`
        );
        const groupDoc = await getDoc(
          doc(getFirestore(), 'groupRecordings', groupId)
        );

        setGroupId(groupId);

        // Check if the event exists
        if (!groupDoc.exists()) {
          setError('Invalid URL');

          return;
        }

        setTopic(topic);
      }

      setEventName(eventDoc.get('name') as string);

      clearLoading();
    }

    try {
      validate();
    } catch (error) {
      console.error(error);
      setError('Something went wrong');
    }
  }, [params]);

  if (loading) {
    return (
      <section className={styles.wrapper}>
        <Helmet>{APP_TITLE}</Helmet>
        <div className={styles.loadingWrapper}>
          <div className={styles.loader}>
            <LoadingSpinnerIcon color="white" size={Size.Large} />
          </div>
          <h1>{loading}</h1>
        </div>
      </section>
    );
  }

  return <>{children}</>;
};
