export const LIGHT_BLUR = 0.2;
export const NORMAL_BLUR = 0.5;

export const Backgrounds = {
  Image1: {
    name: 'White wall',
    url: 'https://images.unsplash.com/photo-1496268280706-ec91c5e133c7?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    thumb:
      'https://images.unsplash.com/photo-1496268280706-ec91c5e133c7?q=80&w=128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  },
  Image2: {
    name: 'Books',
    url: 'https://images.unsplash.com/photo-1543248939-4296e1fea89b?q=80&w=1748&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    thumb:
      'https://images.unsplash.com/photo-1543248939-4296e1fea89b?q=80&w=128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  },
  Image3: {
    name: 'Open office',
    url: 'https://images.unsplash.com/photo-1489769002049-ccd828976a6c?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    thumb:
      'https://images.unsplash.com/photo-1489769002049-ccd828976a6c?q=80&w=128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  }
};
