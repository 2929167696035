import { FormEvent, useState } from 'react';

import styles from './ReviewForm.module.scss';

import {
  FORM_CONTACT_LABEL,
  FORM_MESSAGE_LABEL,
  FORM_MESSAGE_PLACEHOLDER,
  FORM_RATING_LABEL,
  FORM_SUBMIT_BUTTON_TEXT,
  REVIEW_FORM_TITLE
} from '../../../config/ReviewForm';
import { ButtonState, ButtonType } from '../../../constants/buttons';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { Checkbox } from '../../Inputs/Checkbox/Checkbox';
import { Rating } from '../../Inputs/Rating/Rating';
import { TextArea } from '../../Inputs/TextArea/TextArea';

export const INITIAL_REVIEW_FORM_DATA: ReviewFormData = {
  rating: 3,
  message: '',
  contact: false
};

export interface ReviewFormData {
  contact: boolean;
  message: string;
  rating: number;
}

export interface ReviewFormProps {
  onSubmitClicked: (data: ReviewFormData) => Promise<void>;
  submitting: boolean;
}

const REVIEW_FORM_ID = 'review-form';

export const ReviewForm = ({
  submitting,
  onSubmitClicked
}: ReviewFormProps) => {
  const [data, setData] = useState<ReviewFormData>(INITIAL_REVIEW_FORM_DATA);
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Disabled
  );

  const handleRatingChanged = (rating: number) => {
    setButtonState(ButtonState.Enabled);
    setData((c) => ({ ...c, rating }));
  };

  const handleMessageChanged = (message: string) =>
    setData((c) => ({ ...c, message }));

  const handleContactChanged = (contact: boolean) => {
    setButtonState(ButtonState.Enabled);
    setData((c) => ({ ...c, contact }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmitClicked(data);
  };

  return (
    <section className={styles.container}>
      <hgroup className={styles.header}>
        <h1>{REVIEW_FORM_TITLE}</h1>
      </hgroup>

      <form id={REVIEW_FORM_ID} className={styles.form} onSubmit={handleSubmit}>
        <section className={styles.rating}>
          <Rating
            label={FORM_RATING_LABEL}
            rating={data.rating}
            onChange={handleRatingChanged}
          />
        </section>
        <TextArea
          label={FORM_MESSAGE_LABEL}
          placeholder={FORM_MESSAGE_PLACEHOLDER}
          value={data.message}
          onChange={handleMessageChanged}
        />
        <Checkbox
          label={FORM_CONTACT_LABEL}
          checked={data.contact}
          onChange={handleContactChanged}
        />
        <div className={styles.buttonWrapper}>
          <PrimaryButton
            formId={REVIEW_FORM_ID}
            text={FORM_SUBMIT_BUTTON_TEXT}
            state={submitting ? ButtonState.Loading : buttonState}
            type={ButtonType.Submit}
          />
        </div>
      </form>
    </section>
  );
};
