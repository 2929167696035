export const enum TIME_CODE {
  DISABLED_AUDIO = 'disabled_audio',
  DISABLED_VIDEO = 'disabled_video',
  ENABLED_AUDIO = 'enabled_audio',
  ENABLED_VIDEO = 'enabled_video',
  HOST_START_TAKE = 'host_start_take',
  HOST_STOP_TAKE = 'host_stop_take',
  START_SCREEN_SHARE = 'started_screen_share',
  STOP_SCREEN_SHARE = 'stopped_screen_share'
}
