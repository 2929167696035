import styles from './ConfirmModal.module.scss';

import {
  CONFIRM_MODAL_CONFIRM,
  CONFIRM_MODAL_ID
} from '../../../config/ConfirmModal';
import { ButtonState } from '../../../constants/buttons';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { Modal } from '../../Modal/Modal';

export interface ConfirmModalProps {
  cancel: () => void;
  confirm: () => void;
  message: string;
  open: boolean;
  title: string;
}

export const ConfirmModal = ({
  open,
  title,
  message,
  confirm,
  cancel
}: ConfirmModalProps) => (
  <Modal
    open={open}
    title={title}
    modalId={CONFIRM_MODAL_ID}
    onCancel={cancel}
    rightButtons={
      <>
        <PrimaryButton
          text={CONFIRM_MODAL_CONFIRM}
          state={ButtonState.Enabled}
          onClick={confirm}
        />
      </>
    }
  >
    <section className={styles.container}>
      <p>{message}</p>
    </section>
  </Modal>
);
