import { useEffect, useState } from 'react';

import { selectIsLocalScreenShared, useHMSStore } from '@100mslive/react-sdk';

import { useRecordingContext } from '../providers/RecordingProvider';

export default function useScreenMediaRecorder() {
  const isScreenShared = useHMSStore(selectIsLocalScreenShared);

  const { isRecording, uploadScreen, storeScreenChunk } = useRecordingContext();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  const createScreenMediaRecorder = (ref: MediaRecorder) => {
    if (!ref && mediaRecorder?.state === 'recording') {
      mediaRecorder.stop();
    }

    setMediaRecorder(ref);
  };

  const stopScreenMediaRecorder = () => {
    if (mediaRecorder?.state === 'recording') {
      mediaRecorder.stop();
    } else if (mediaRecorder?.state === 'inactive') {
      setMediaRecorder(null);
    }
  };

  useEffect(() => {
    if (mediaRecorder) {
      // Handle dataavailable event
      mediaRecorder.ondataavailable = (event) => {
        uploadScreen(event.data);
        storeScreenChunk(event.data);
      };

      // Handle stop event
      mediaRecorder.onstop = () => {
        if (!isScreenShared) setMediaRecorder(null);
      };

      mediaRecorder.onstart = () => {};

      mediaRecorder.onerror = () => {};
    }
  }, [mediaRecorder]);

  useEffect(() => {
    if (mediaRecorder) {
      if (isScreenShared && isRecording && mediaRecorder.state === 'inactive') {
        mediaRecorder.start();
      } else if (!isRecording && mediaRecorder.state === 'recording') {
        mediaRecorder.stop();
      }
    }
  }, [isRecording, mediaRecorder, isScreenShared]);

  return { createScreenMediaRecorder, stopScreenMediaRecorder };
}
