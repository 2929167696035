import { PropsWithChildren, createContext, useContext } from 'react';

import useCameraMediaRecorder from '../hooks/useCameraMediaRecorder';
import useScreenMediaRecorder from '../hooks/useScreenMediaRecorder';

export interface MediaRecorderContextType {
  createCameraMediaRecorder: (ref: MediaRecorder) => void;
  createScreenMediaRecorder: (ref: MediaRecorder) => void;
  hasCameraMediaRecorder: boolean;
  stopCameraMediaRecorder: () => void;
  stopScreenMediaRecorder: () => void;
}

export const MediaRecorderContext = createContext<MediaRecorderContextType>(
  null!
);

export default function MediaRecorderProvider(props: PropsWithChildren) {
  const {
    createCameraMediaRecorder,
    stopCameraMediaRecorder,
    cameraMediaRecorder
  } = useCameraMediaRecorder();
  const { createScreenMediaRecorder, stopScreenMediaRecorder } =
    useScreenMediaRecorder();

  return (
    <MediaRecorderContext.Provider
      value={{
        hasCameraMediaRecorder: Boolean(cameraMediaRecorder),
        createCameraMediaRecorder,
        stopCameraMediaRecorder,
        createScreenMediaRecorder,
        stopScreenMediaRecorder
      }}
    >
      {props.children}
    </MediaRecorderContext.Provider>
  );
}

export function useMediaRecorderContext() {
  const context = useContext(MediaRecorderContext);
  if (!context) {
    throw new Error(
      'useMediaRecorderContext must be used within the MediaRecorderContext'
    );
  }

  return context;
}
