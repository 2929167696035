import { HTMLInputTypeAttribute } from 'react';

import styles from './TextInput.module.scss';

export const enum TEXT_INPUT_AUTOCOMPLETE {
  EMAIL = 'email',
  FIRST_NAME = 'given-name',
  LAST_NAME = 'family-name',
  OFF = 'off'
}
export interface TextInputProps {
  autocomplete?: TEXT_INPUT_AUTOCOMPLETE;
  id?: string;
  label?: string;
  maxLength?: number;
  minLength?: number;
  name?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  value: string;
}

export const TextInput = ({
  id,
  name,
  type,
  label,
  placeholder,
  autocomplete,
  value,
  minLength,
  maxLength,
  onChange
}: TextInputProps) => (
  <div className={styles.wrapper}>
    {label && (
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    )}
    <input
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      minLength={minLength}
      maxLength={maxLength}
      autoComplete={autocomplete}
      className={styles.input}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
