export const END_TAKE_MODAL_ID = 'help-modal-id';

export const END_TAKE_MODAL_TITLE = 'Finish Take';
export const END_TAKE_MODAL_DESCRIPTION =
  'Please provide all the assets you used during the presentation ';
export const END_TAKE_MODAL_DESCRIPTION_REQUIRED =
  '(required as you shared your screen)';
export const END_TAKE_MODAL_FINISH_BUTTON_LABEL = 'Finish Take';
export const END_TAKE_MODAL_NEXT_BUTTON_LABEL = 'Next';
export const END_TAKE_MODAL_BACK_BUTTON_LABEL = 'Back';
export const END_TAKE_MODAL_DISMISS_BUTTON_LABEL = 'Dismiss';
export const END_TAKE_MODAL_DELETE_BUTTON_LABEL = 'Delete Take';
export const END_TAKE_MODAL_ASSETS_LABEL =
  'Drag and drop your presentation files here, or click to select files';
export const END_TAKE_MODAL_NOTES_LABEL = 'Notes';
export const END_TAKE_MODAL_NOTES_PLACEHOLDER = 'Leave comments here';

export const END_TAKE_MODAL_ERROR_REQUIRED_ASSETS =
  'Assets are required as you shared your screen';

export const END_TAKE_COMPLETE_DELETE_MESSAGE =
  'Your take has been deleted. You can record another take or end the session.';

export const END_TAKE_COMPLETE_SAVE_MESSAGE_1 = 'Your take has been saved!';

export const END_TAKE_COMPLETE_SAVE_MESSAGE_2 =
  'You can record another take or end the session.';

export const END_TAKE_COMPLETE_SAVE_GROUP_MESSAGE_2 =
  'We will email you a link to view the group session shortly. You can record another take or end the session.';

export const END_TAKE_COMPLETE_DELETE_MESSAGE_1 = 'Your take has been deleted!';

export const END_TAKE_COMPLETE_DELETE_MESSAGE_2 =
  'You can record another take or end the session.';
