import { useState } from 'react';

import styles from './CommentBox.module.scss';

import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';

export interface CommentBoxProps {
  label?: string;
  onSubmitComment: (value: string) => void;
  placeholder?: string;
}

export const CommentBox = ({
  label,
  placeholder,
  onSubmitComment
}: CommentBoxProps) => {
  const [comment, setComment] = useState('');

  const onCommentSubmit = () => {
    onSubmitComment(comment);
    setComment('');
  };

  return (
    <div className={styles.wrapper}>
      {label && (
        <label htmlFor="textarea" className={styles.label}>
          {label}
        </label>
      )}
      <div className={styles.box}>
        <textarea
          id="textarea"
          placeholder={placeholder}
          value={comment}
          className={styles.input}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className={styles.controls}>
          <PrimaryButton text="Comment" onClick={onCommentSubmit} />
        </div>
      </div>
    </div>
  );
};
