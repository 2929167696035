export const truncateText = (text: string, length: number = 100) =>
  text.length < length ? text : `${text.substring(0, length)}...`;

export const removeBrackets = (text: string) => text.replace(/\s*\(.*?\)/g, '');

export const getInitials = (name: string) => {
  const nameParts = name.trim().split(' ');
  if (nameParts.length > 1) {
    // If more than one name part, take first letter of each
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[1].charAt(0).toUpperCase()
    );
  } else {
    // Otherwise, take the first two letters
    return name.substring(0, 2).toUpperCase();
  }
};
