export enum AspectRatio {
  '16:9' = '16:9',
  '1:1' = '1:1',
  '3:4' = '3:4',
  '4:3' = '4:3',
  '9:16' = '9:16'
}

export const PARTICIPANT_PADDING = 32;

export const MAX_PARTICIPANTS = 10;
