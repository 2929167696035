export const enum UploadState {
  DONE = 'done',
  NOT_STARTED = 'not_started',
  UNKNOWN = 'unknown',
  UPLOADING = 'uploading'
}

export interface ParticipantDetails {
  id: string;
  progress: string;
  state: UploadState;
}
