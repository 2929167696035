import { ReactElement } from 'react';

import styles from './Background.module.scss';

interface BackgroundProps {
  children?: ReactElement | ReactElement[];
}

export const Background = ({ children }: BackgroundProps) => (
  <div className={styles.container}>{children}</div>
);
