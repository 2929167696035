'use client';

import { MouseEvent, useCallback, useEffect, useRef } from 'react';

import cs from 'classnames';

import styles from './BackgroundCancel.module.scss';

interface BackgroundProps {
  cancelFunction: () => void;
}

export const BackgroundCancel = ({ cancelFunction }: BackgroundProps) => {
  const wrapperClass = cs({
    [styles.wrapper]: true
  });

  const backgroundRef = useRef<HTMLDivElement>(null);

  const cancelCallback = useCallback(() => cancelFunction(), [cancelFunction]);

  useEffect(() => {
    const backgroundCancelFunction = (
      event: Event | MouseEvent<HTMLElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();

      cancelCallback();
    };

    const backgroundElement = backgroundRef.current;

    if (backgroundElement) {
      backgroundElement.addEventListener('mousedown', backgroundCancelFunction);
    }

    return () => {
      if (backgroundElement) {
        backgroundElement.removeEventListener(
          'mousedown',
          backgroundCancelFunction
        );
      }
    };
  }, [cancelCallback]);

  return (
    <div
      className={wrapperClass}
      ref={backgroundRef}
      role="button"
      aria-label="Background removal function"
    />
  );
};
