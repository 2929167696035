import cs from 'classnames';

import style from './Toast.module.scss';

import { truncateText } from '../../utils/text';
import { Close } from '../Icons/Close/Close';

export const enum ToastType {
  Error = 'error',
  Info = 'info'
}

export interface ToastProps {
  subtitle?: string;
  title: string;
  type: ToastType;
}

export const Toast = ({ title, subtitle, type }: ToastProps) => {
  const containerClass = cs({
    [style.container]: true,
    [style[type]]: true
  });

  return (
    <section className={containerClass}>
      <div className={style.content}>
        <p>{title}</p>
        {subtitle && <small>{truncateText(subtitle)}</small>}
      </div>
      <button>
        <Close />
      </button>
    </section>
  );
};
