import { FormEvent } from 'react';

import { DeviceType, HMSPeer } from '@100mslive/react-sdk';

import styles from './DeviceSelectForm.module.scss';

import Participant from '../../../components/Participant/Participant';
import { ButtonState, ButtonType } from '../../../constants/buttons';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';
import { Select } from '../../Inputs/Select/Select';
import { Option } from '../../Inputs/Select/SelectMenu/SelectOption/SelectOption';
import { Tooltip, TooltipPosition } from '../../Tooltip/Tooltip';

export interface DeviceSelectFormProps {
  audioInputs: Option[];
  audioOutputs: Option[];
  disableJoin: boolean;
  loadingPeer: boolean;
  onDeviceChanged: (selected: Option, type: DeviceType) => void;
  onStartClicked: () => Promise<void>;
  peer?: HMSPeer;
  selectedAudioInput?: Option;
  selectedAudioOutput?: Option;
  selectedVideoInput?: Option;
  showPrimaryButton?: boolean;
  videoInputs: Option[];
  waitingForHost?: boolean;
}

const DEVICE_SELECT_FORM_ID = 'device-select-form';

export const DeviceSelectForm = ({
  disableJoin,
  selectedAudioInput,
  videoInputs,
  selectedVideoInput,
  audioInputs,
  selectedAudioOutput,
  audioOutputs,
  loadingPeer,
  peer,
  onStartClicked,
  onDeviceChanged,
  showPrimaryButton = true
}: DeviceSelectFormProps) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onStartClicked();
  };

  return (
    <section className={styles.wrapper}>
      {loadingPeer || !peer ? (
        <div className={styles.display}>
          <div className={styles.inner}>
            <LoadingSpinnerIcon color="white" />
          </div>
        </div>
      ) : (
        <Participant peer={peer} isPreview={true} />
      )}

      <form
        id={DEVICE_SELECT_FORM_ID}
        className={styles.form}
        onSubmit={handleSubmit}
      >
        <Select
          label="Select Video Input"
          onChange={(value) => onDeviceChanged(value, DeviceType.videoInput)}
          options={videoInputs}
          selected={selectedVideoInput}
          placeholder="Select a video input"
        />
        <Select
          label="Select Audio Input"
          onChange={(value) => onDeviceChanged(value, DeviceType.audioInput)}
          options={audioInputs}
          selected={selectedAudioInput}
          placeholder="Select an audio input"
        />
        <Select
          label="Select Audio Output"
          onChange={(value) => onDeviceChanged(value, DeviceType.audioOutput)}
          options={audioOutputs}
          selected={selectedAudioOutput}
          placeholder="Select an audio output"
        />

        {showPrimaryButton && (
          <div className={styles.buttonWrapper}>
            <Tooltip
              text="The host needs to join before you can join"
              position={TooltipPosition.WEST}
              disabled={!disableJoin || loadingPeer}
            >
              <PrimaryButton
                formId={DEVICE_SELECT_FORM_ID}
                text="Start"
                state={
                  selectedVideoInput &&
                  selectedAudioInput &&
                  selectedAudioOutput &&
                  !disableJoin
                    ? ButtonState.Enabled
                    : ButtonState.Disabled
                }
                type={ButtonType.Submit}
              />
            </Tooltip>
          </div>
        )}
      </form>

      {disableJoin && !loadingPeer && (
        <div className={styles.waitingContainer}>
          <h4>Waiting for host to join...</h4>
        </div>
      )}
    </section>
  );
};
