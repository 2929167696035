import { ReactNode } from 'react';

import styles from './Footer.module.scss';

export interface FooterProps {
  center?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}

export const Footer = ({ left, center, right }: FooterProps) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      {left && <div className={styles.left}>{left}</div>}

      {center && <div className={styles.center}>{center}</div>}

      {right && <div className={styles.right}>{right}</div>}
    </div>
  </footer>
);
