import { useEffect, useRef, useState } from 'react';

import { selectLocalPeer } from '@100mslive/react-sdk';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Upload } from '@aws-sdk/lib-storage';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import md5 from 'md5';

import { awsConfig } from '../config/aws';
import { METADATA_FILE_ID } from '../constants/metadata';
import { hmsStore } from '../hms';
import { useAppState } from '../providers/AppState';
import { formatBytes, zeroPadding } from '../utils/format';
import useUploadProgress from './useUploadProgress';

const enum ChunkType {
  Camera = 'camera',
  Screen = 'screen'
}

const ChunkPaths = {
  [ChunkType.Camera]: 'recording-chunks',
  [ChunkType.Screen]: 'screen-recordings/originals'
};

export interface Chunk {
  blob: Blob;
  filename: string;
  index: number;
  type: ChunkType;
}

interface S3ChunkUploaderHook {
  isComplete: boolean;
  isUploading: boolean;
  progress: number;
  progressText: string;
  uploadChunk: (blob: Blob) => void;
  uploadScreen: (blob: Blob) => void;
}

interface S3ChunkUploaderProps {
  cameraChunkNumber: number;
  incrementCameraChunkNumber: () => void;
  incrementScreenChunkNumber: () => void;
  isRecording: boolean;
  resetChunkNumber: () => void;
  screenChunkNumber: number;
  session: number;
  take: number;
}

const useS3ChunkUploader = ({
  isRecording,
  session,
  take,
  cameraChunkNumber,
  screenChunkNumber,
  incrementCameraChunkNumber,
  incrementScreenChunkNumber,
  resetChunkNumber
}: S3ChunkUploaderProps): S3ChunkUploaderHook => {
  const { eventId, sessionData } = useAppState();
  const localPeer = hmsStore.getState(selectLocalPeer);
  const { updateUploadProgress, completeUploadProgress } = useUploadProgress();

  const [uploadQueue, setUploadQueue] = useState<Chunk[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const cameraFilenameRef = useRef<string>();
  const screenFilenameRef = useRef<string>();

  const [totalUploadSize, setTotalUploadSize] = useState(0);
  const [totalUploadedSize, setTotalUploadedSize] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState('0 B/0 B');

  // Create S3 reference
  const s3 = useRef(
    new S3Client({
      region: awsConfig.region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: awsConfig.region }),
        identityPoolId: awsConfig.identityPoolId
      }),
      maxAttempts: awsConfig.maxAttempts,
      requestHandler: new XhrHttpHandler({}) // https://github.com/aws/aws-sdk-js-v3/tree/main/packages/xhr-http-handler
    })
  );

  useEffect(() => {
    if (isRecording) {
      setUploadQueue([]);
      setTotalUploadSize(0);
      setTotalUploadedSize(0);
      setProgress(0);
      setProgressText('0 B/0 B');
      setIsComplete(false);
      resetChunkNumber();
    }
  }, [isRecording]);

  useEffect(() => {
    const uploadNextChunk = async () => {
      if (!localPeer || !sessionData) {
        console.error('Missing localPeer or sessionData');

        return;
      }

      if (uploadQueue.length > 0 && !isUploading) {
        setIsUploading(true);
        setIsComplete(false);

        const { filename, blob, type } = uploadQueue[0];

        const metadata: Record<string, string> = {};
        if (type === ChunkType.Screen) {
          // TODO: Double check this, should be the same as the merge chunks worker
          metadata[METADATA_FILE_ID] = md5(filename);
        }
        console.log(`Adding metadata`, metadata);
        console.log(
          `Uploading file to: ${ChunkPaths[type]}/${eventId}/${sessionData.uploadId}/${localPeer.id}/${filename}.webm`
        );

        const uploadTask = new Upload({
          client: s3.current,
          // tags: [...], // optional tags
          queueSize: 1, // optional concurrency configuration
          // partSize: 5MB, // optional size of each part
          leavePartsOnError: false, // optional manually handle dropped parts
          params: {
            Bucket: awsConfig.bucket,
            Key: `${ChunkPaths[type]}/${eventId}/${sessionData.uploadId}/${localPeer.id}/${filename}.webm`,
            Body: blob,
            ContentType: 'video/webm',
            Metadata: metadata
          }
        });

        try {
          uploadTask.on('httpUploadProgress', (progress) => {
            const totalCompleted = (progress.loaded || 0) + totalUploadedSize;
            const percentage = (totalCompleted / totalUploadSize) * 100;
            const progressText = `${formatBytes(totalCompleted)}/${formatBytes(
              totalUploadSize
            )}`;

            setProgress(percentage);
            setProgressText(progressText);

            updateUploadProgress(
              sessionData.uploadId,
              localPeer.id,
              `${percentage.toFixed(1)}%`
            );
          });

          await uploadTask.done();

          setTotalUploadedSize((prevSize) => prevSize + blob.size);
          setUploadQueue((prevQueue) => prevQueue.slice(1));
        } catch (error) {
          await uploadTask.abort();

          // TODO: Handle errors better
          throw new Error('upload-network');
        }

        setIsUploading(false);
      } else if (!isRecording && uploadQueue.length === 0) {
        setIsComplete(true);
        completeUploadProgress(sessionData.uploadId, localPeer.id);
      }
    };

    // Upload the next chunk when the queue changes
    uploadNextChunk();
  }, [uploadQueue, isUploading]);

  // Add the chunk to the upload queue
  const uploadChunk = (blob: Blob) => {
    const chunk: Chunk = {
      index: cameraChunkNumber,
      filename: cameraFilenameRef.current!,
      blob,
      type: ChunkType.Camera
    };
    setUploadQueue((prevQueue) => [...prevQueue, chunk]);
    setTotalUploadSize((prevSize) => prevSize + chunk.blob.size);
    incrementCameraChunkNumber();
  };

  // Add the screen blob to the upload queue
  // TODO: This could be chunks too
  const uploadScreen = (blob: Blob) => {
    const chunk: Chunk = {
      index: 0,
      filename: screenFilenameRef.current!,
      blob,
      type: ChunkType.Screen
    };
    setUploadQueue((prevQueue) => [...prevQueue, chunk]);
    setTotalUploadSize((prevSize) => prevSize + chunk.blob.size);
    incrementScreenChunkNumber();
  };

  useEffect(() => {
    cameraFilenameRef.current = `${zeroPadding(take)}_${zeroPadding(
      cameraChunkNumber
    )}_${zeroPadding(session)}_camera`;
  }, [session, take, cameraChunkNumber]);

  useEffect(() => {
    screenFilenameRef.current = `${zeroPadding(take)}_${zeroPadding(
      screenChunkNumber
    )}_${zeroPadding(session)}_screen`;
  }, [session, take, screenChunkNumber]);

  return {
    isUploading,
    isComplete,
    progress,
    progressText,
    uploadChunk,
    uploadScreen
  };
};

export default useS3ChunkUploader;
