import { ReactNode } from 'react';

import cs from 'classnames';

import styles from './PrimaryIconButton.module.scss';

import { ButtonState, ButtonType } from '../../../constants/buttons';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';

export interface PrimaryIconButtonProps {
  icon: ReactNode;
  notification?: boolean;
  onClick?: () => void | Promise<void>;
  state?: ButtonState;
  type?: ButtonType;
}

export const PrimaryIconButton = ({
  icon,
  state = ButtonState.Enabled,
  type = ButtonType.Button,
  notification = false,
  onClick
}: PrimaryIconButtonProps) => (
  <button
    type={type}
    className={cs({
      [styles.button]: true,
      [styles[state]]: true
    })}
    onClick={onClick}
    disabled={state === ButtonState.Loading || state === ButtonState.Disabled}
  >
    {state === ButtonState.Loading ? (
      <LoadingSpinnerIcon color="white" />
    ) : (
      icon
    )}
    {notification && <span className={styles.notification} />}
  </button>
);
