import { FormEvent, useState } from 'react';

import cs from 'classnames';

import styles from './Select.module.scss';

import { BackgroundCancel } from '../../BackgroundCancel/BackgroundCancel';
import { ArrowDown } from '../../Icons/ArrowDown/ArrowDown';
import { SelectMenu } from './SelectMenu/SelectMenu';
import { Option } from './SelectMenu/SelectOption/SelectOption';

export interface SelectProps {
  dark?: boolean;
  label: string;
  onChange: (selected: Option) => void;
  options: Option[];
  placeholder?: string;
  selected?: Option;
}

export const Select = ({
  options,
  label,
  selected,
  placeholder,
  onChange,
  dark = false
}: SelectProps) => {
  const [open, setOpen] = useState(false);

  const labelClass = cs({
    [styles.label]: true,
    [styles.dark]: dark
  });

  const arrowClass = cs({
    [styles.expandArrow]: true,
    [styles.rotate]: open
  });

  const toggleSelected = (selected: Option) => {
    setOpen(false);
    onChange(selected);
  };

  const handleSelectClicked = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setOpen((c) => !c);
  };

  return (
    <div className={styles.wrapper}>
      <label htmlFor="dropdown" className={labelClass}>
        {label}
      </label>
      <button
        id="dropdown"
        className={styles.selectToggle}
        onClick={handleSelectClicked}
      >
        <div className={styles.selectText}>
          <span>{selected?.label || placeholder}</span>
          <span className={arrowClass}>
            <ArrowDown scale={0.8} />
          </span>
        </div>
      </button>

      <SelectMenu
        open={open}
        onChange={toggleSelected}
        selected={selected}
        options={options}
      />

      {open && <BackgroundCancel cancelFunction={() => setOpen(false)} />}
    </div>
  );
};
