import colors from '../../styles/colors.module.scss';

import { XMLNS } from '../../constants/Icons';

export interface LogoProps {
  color?: string;
  scale?: number;
}

export const Logo = ({ color = colors.black, scale = 1 }: LogoProps) => (
  <svg
    width={scale * 1405.88}
    height={scale * 1126.07}
    viewBox="0 0 1405.88 1126.07"
    fill={color}
    xmlns={XMLNS}
  >
    <path
      strokeWidth="60"
      stroke={color}
      d="M1086,1202.58c-140.48,0-259.22-93.07-297.92-220.93a308,308,0,0,1-8.81-37.32,312.54,312.54,0,0,1-4.5-53c0-2.66,0-5.32.11-8A310,310,0,0,1,788.3,800.3c37.51-122.83,149-213.39,282.47-219.82q7.56-.36,15.22-.36a310.72,310.72,0,0,1,254.53,132.09q16.43-16,33.53-31.25,6.77-6,13.62-12A373.16,373.16,0,0,0,1128.4,518.6C1102.75,337.34,947,197.94,758.64,197.94S414.37,337.48,388.83,518.87C199.91,537.43,52.32,696.74,52.32,890.52,52.32,1096.79,219.54,1264,425.8,1264c143.41,0,267.92-80.81,330.51-199.39,62.71,118.15,187,198.61,330.09,198.61,120.76,0,228.13-57.31,296.39-146.22-15.08-14.12-30.44-27.91-47.37-39.46A310.8,310.8,0,0,1,1086,1202.58ZM755.89,715.65A371.73,371.73,0,0,0,713,880.29c-.09,3.13-.12,6.29-.12,9.45a375.53,375.53,0,0,0,3.71,52.82,368.9,368.9,0,0,0,8.16,41c-39.95,125.78-157.67,216.91-296.68,216.91-171.88,0-311.23-139.35-311.23-311.23,0-157.35,116.78-287.44,268.4-308.3A311.94,311.94,0,0,1,428.11,578q10.27,0,20.37.65-.08-3-.07-6.08A312.57,312.57,0,0,1,453.17,518c25.76-145.9,153.17-256.75,306.48-256.75,152.87,0,280,110.22,306.26,255.53C931.2,524.1,815.47,602.77,755.89,715.65Z"
      transform="translate(-22.32 -167.94)"
    />
  </svg>
);

export const LogoWithText = ({
  color = colors.black,
  scale = 1
}: LogoProps) => (
  <svg
    width={scale * 7270.33}
    height={scale * 1138.92}
    viewBox="0 0 7270.33 1138.92"
    fill={color}
    xmlns={XMLNS}
  >
    <path
      strokeWidth="60"
      stroke={color}
      d="M1148.83,1110.75c-140.48,0-259.22-93.07-297.92-220.93a308,308,0,0,1-8.81-37.32,312.45,312.45,0,0,1-4.5-53c0-2.66,0-5.32.1-8a310.42,310.42,0,0,1,13.44-83.09c37.51-122.83,149-213.38,282.47-219.81q7.56-.37,15.22-.37a310.74,310.74,0,0,1,254.53,132.09q16.42-16,33.53-31.25,6.77-6,13.62-12a373.16,373.16,0,0,0-259.27-150.38c-25.65-181.26-181.43-320.66-369.77-320.66S477.21,245.66,451.66,427c-188.91,18.56-336.5,177.87-336.5,371.65,0,206.27,167.21,373.48,373.48,373.48,143.4,0,267.92-80.81,330.51-199.38,62.71,118.14,187,198.6,330.09,198.6,120.75,0,228.12-57.31,296.39-146.22-15.08-14.12-30.44-27.9-47.38-39.46A310.76,310.76,0,0,1,1148.83,1110.75ZM818.72,623.82a371.9,371.9,0,0,0-42.84,164.64q-.14,4.71-.13,9.45a375.53,375.53,0,0,0,3.72,52.82,368.9,368.9,0,0,0,8.16,40.95C747.68,1017.46,630,1108.59,491,1108.59c-171.89,0-311.23-139.34-311.23-311.23,0-157.35,116.78-287.44,268.39-308.3A312.08,312.08,0,0,1,491,486.13q10.26,0,20.37.65c-.05-2-.07-4-.07-6.08A313.55,313.55,0,0,1,516,426.21c25.77-145.9,153.18-256.74,306.48-256.74,152.87,0,280,110.21,306.26,255.52C994,432.27,878.31,510.94,818.72,623.82Z"
      transform="translate(-85.16 -76.11)"
    />
    <g>
      <path
        d="M1560.56,475h134v563.8q0,36.14,12,48.67t33,12.53a143.38,143.38,0,0,0,33-4,157.58,157.58,0,0,0,30-10l18,102q-27,13-63,20t-65,7q-63,0-97.5-33.59t-34.5-95.75Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M2120.54,1215q-64,0-115-21.26t-86.5-58.85a264.44,264.44,0,0,1-54.5-86,276.5,276.5,0,0,1-19-101.86q0-54.37,19-102.84a265,265,0,0,1,54.5-86.05q35.49-37.56,86.5-58.84t115-21.27q64,0,114.5,21.27t86.5,58.84a254.55,254.55,0,0,1,54.5,86.05q18.49,48.46,18.5,102.84a283.24,283.24,0,0,1-18.5,101.86,258.06,258.06,0,0,1-54,86q-35.51,37.61-86.5,58.85T2120.54,1215Zm-137-268q0,45.13,18,80t49,54.45q31,19.62,70,19.61,38,0,69-20.11T2239,1026q18.49-34.83,18.5-80,0-44.16-18.5-79.47t-49.5-54.92q-31-19.62-69-19.63-39,0-70,20.11t-49,54.94Q1983.54,901.91,1983.54,947Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M2479.53,1011V686h134V981.47q0,59.29,22,89.42t65,30.14a136.6,136.6,0,0,0,51.5-10.28,147.36,147.36,0,0,0,47-30.84q21.49-20.58,36.5-51.92V686h134v369.5q0,20.74,7.5,29.64t24.5,10.86v109q-20,3-33.5,4.5a223.67,223.67,0,0,1-24.5,1.5q-36,0-58.5-16.36t-26.5-45.06l-3-41.56q-35,52.5-90,79.75t-121,27.23q-81,0-123-52T2479.53,1011Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M3070.53,946q0-75.13,30-136t83-96.43q53-35.6,121-35.61,58,0,104.5,28.5t72.5,72.5V475h134v580q0,21,7,30t24,11v109q-35,7-57,7-36,0-59.5-18t-26.5-47l-2-33q-29,49-78.5,75t-103.5,26q-54,0-99.5-20.76a242,242,0,0,1-79-57.36q-33.53-36.59-52-85.55T3070.53,946Zm411,68.68V892.1a138.49,138.49,0,0,0-32-51,177.53,177.53,0,0,0-49-35.8q-27-13.23-54-13.25-31,0-56,12.75a141.18,141.18,0,0,0-43.5,34.35,147.49,147.49,0,0,0-28,50,187.2,187.2,0,0,0-9.5,59.83,167.27,167.27,0,0,0,10.5,59.34,138.69,138.69,0,0,0,30.5,48.56,147.48,147.48,0,0,0,46.5,32.38q26.47,11.76,57.5,11.76a123.46,123.46,0,0,0,38.5-6.37,159.44,159.44,0,0,0,37-17.66,136.31,136.31,0,0,0,31-27.47A120.18,120.18,0,0,0,3481.53,1014.71Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M3758.52,1205V495h301q49,0,90,20a232.44,232.44,0,0,1,71.5,54,258.75,258.75,0,0,1,47.5,76,227.59,227.59,0,0,1,17,86,244.49,244.49,0,0,1-28,114q-28,54-77,88t-116,34h-168v238Zm138-359h160a76.57,76.57,0,0,0,45-14q20-14,32-40.5t12-60.5q0-37-13.5-63t-35-39a88.5,88.5,0,0,0-46.5-13h-154Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M4680.5,797q-61,0-109,23.5t-69,67.5v317h-134V686h123V793q28-54,71-85t91-34h17a43,43,0,0,1,10,1Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M4991.51,1215q-63,0-114-21.25t-87-58.34a263.43,263.43,0,0,1-55.5-85A266.51,266.51,0,0,1,4715.51,949q0-74.14,33.5-135.5t96-98.4q62.47-37.08,147.5-37.1,86,0,147,37.14t93.5,98.07q32.47,60.91,32.5,131.23c0,7.92-.35,16-1,24.27s-1.35,15-2,20.29h-405q4,39.11,23.5,68.39t50,44.47a145.13,145.13,0,0,0,65.5,15.14,154.55,154.55,0,0,0,75.5-19.5q35.47-19.5,48.5-51.5l115,32q-19,40-54.5,71t-83.5,48.5Q5049.51,1215,4991.51,1215Zm-137-311h274q-4-39.09-22.5-67.92a129.86,129.86,0,0,0-48.5-45q-30-16.12-67-16.13-36,0-65.5,16.13a130.65,130.65,0,0,0-48,45Q4858.48,864.94,4854.51,904Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M5540.51,1215a374.41,374.41,0,0,1-128-22q-61-22-105-65l48-87q47,38,92.5,56t87.5,18q39,0,62.5-13.5t23.5-40.5q0-18.75-13-29.11t-37.5-18.26q-24.53-7.9-59.5-17.77-58-18.75-97-36.53t-58.5-44.9q-19.5-27.15-19.5-69.6,0-51.33,26.5-88.34t74-57.75q47.47-20.71,111.5-20.74,56,0,106,17.5t91,56.5l-54,85q-40-31-76-45.5a194.27,194.27,0,0,0-73-14.5A152.66,152.66,0,0,0,5503,782q-18.53,4.92-30,16.25t-11.5,32q0,18.7,10.5,29t32,18.2q21.48,7.88,52.5,17.72,63,17.73,106.5,36.43t65.5,45.76q22,27.07,22,74.31,0,76.78-57,120.1T5540.51,1215Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M6084.49,1215q-63,0-114-21.25t-87-58.34a263.43,263.43,0,0,1-55.5-85A266.51,266.51,0,0,1,5808.49,949q0-74.14,33.5-135.5t96-98.4q62.49-37.08,147.5-37.1,86,0,147,37.14t93.5,98.07q32.49,60.91,32.5,131.23,0,11.88-1,24.27t-2,20.29h-405q4,39.11,23.5,68.39t50,44.47a145.19,145.19,0,0,0,65.5,15.14,154.5,154.5,0,0,0,75.5-19.5q35.49-19.5,48.5-51.5l115,32q-19,40-54.5,71t-83.5,48.5Q6142.49,1215,6084.49,1215Zm-137-311h274q-4-39.09-22.5-67.92a129.78,129.78,0,0,0-48.5-45q-30-16.12-67-16.13-36,0-65.5,16.13a130.81,130.81,0,0,0-48,45Q5951.48,864.94,5947.49,904Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M6927.49,1205h-134V915.28q0-62.09-22-90.67t-61-28.58q-27,0-54.5,13.68a169.58,169.58,0,0,0-49.5,37.59,146.35,146.35,0,0,0-32,55.17V1205h-134V686h121v96.82a194.85,194.85,0,0,1,51-56.86q31-23.24,71.5-35.6a291.66,291.66,0,0,1,85.5-12.36q49,0,80.5,17.32t48,46.47q16.5,29.14,23,65.75a423.32,423.32,0,0,1,6.5,74.15Z"
        transform="translate(-85.16 -76.11)"
      />
      <path
        d="M7355.49,1179q-17,7-41,16a332.34,332.34,0,0,1-51.5,14.5,282.92,282.92,0,0,1-55.5,5.5q-37,0-68-12.87A107,107,0,0,1,7090,1161q-18.51-28.21-18.5-73.79V789h-69V686h69V516h134V686h110V789h-110v252.68q1,26.64,15,38.48t35,11.84a123,123,0,0,0,41-7q20-7,32-12Z"
        transform="translate(-85.16 -76.11)"
      />
    </g>
  </svg>
);
