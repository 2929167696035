import { HMSPeer, HMSScreenVideoTrack, useVideo } from '@100mslive/react-sdk';

import { ScreenDisplay } from '../../stories/ScreenDisplay/ScreenDisplay';

export interface ScreenParticipantProps {
  isRecording: boolean;
  peer: HMSPeer;
  track: HMSScreenVideoTrack;
}

export default function ScreenParticipant({
  isRecording,
  track,
  peer
}: ScreenParticipantProps) {
  const { videoRef } = useVideo({
    trackId: track.id
  });

  return (
    <ScreenDisplay
      isRecording={isRecording}
      participantName={peer.name}
      videoOn={true}
      videoRef={videoRef}
    />
  );
}
