export const LottieAnimationType = {
  NotFound: 'not-found',
  Error: 'error',
  RecordingDot: 'recording-dot',
  Completed: 'completed',
  Uploading: 'uploading',
  UploadingLight: 'uploading_light',
  Deleted: 'deleted'
} as const;

export type LottieAnimationTypeValues =
  (typeof LottieAnimationType)[keyof typeof LottieAnimationType];
