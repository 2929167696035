import colors from '../../../styles/colors.module.scss';

import { XMLNS } from '../../../constants/Icons';
import { BaseIcon, IconProps } from '../BaseIcon/BaseIcon';

export const Blur = ({ color = colors.black, scale = 1 }: IconProps) => (
  <BaseIcon>
    <svg
      width={scale * 26}
      height={scale * 26}
      viewBox="0 0 24 24"
      fill={color}
      xmlns={XMLNS}
    >
      <circle cx="0.895148" cy="9.19837" r="0.895148" stroke="none"></circle>
      <circle cx="0.895148" cy="14.1339" r="0.895148" stroke="none"></circle>
      <circle cx="23.1051" cy="9.19837" r="0.895148" stroke="none"></circle>
      <circle cx="23.1051" cy="14.1339" r="0.895148" stroke="none"></circle>
      <circle cx="9.53187" cy="0.895148" r="0.895148" stroke="none"></circle>
      <circle cx="14.4684" cy="0.895148" r="0.895148" stroke="none"></circle>
      <circle cx="14.4684" cy="23.1046" r="0.895148" stroke="none"></circle>
      <circle cx="9.53187" cy="23.1046" r="0.895148" stroke="none"></circle>
      <circle cx="5.02959" cy="4.82452" r="1.12334" stroke="none"></circle>
      <circle cx="9.61358" cy="4.82452" r="1.12334" stroke="none"></circle>
      <circle cx="14.1956" cy="4.82452" r="1.12334" stroke="none"></circle>
      <circle cx="18.7786" cy="4.82452" r="1.12334" stroke="none"></circle>
      <circle cx="18.7786" cy="9.40801" r="1.12334" stroke="none"></circle>
      <circle cx="18.7786" cy="13.9905" r="1.12334" stroke="none"></circle>
      <circle cx="18.7786" cy="18.5731" r="1.12334" stroke="none"></circle>
      <circle cx="5.02959" cy="18.5731" r="1.12334" stroke="none"></circle>
      <circle cx="5.02959" cy="13.9905" r="1.12334" stroke="none"></circle>
      <circle cx="5.02959" cy="9.40801" r="1.12334" stroke="none"></circle>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9364 10.2191C13.9364 11.4114 12.9609 12.387 11.7685 12.387C10.5762 12.387 9.60069 11.4114 9.60069 10.2191C9.60069 9.0268 10.5762 8.05127 11.7685 8.05127C12.9609 8.05127 13.9364 9.0268 13.9364 10.2191ZM11.9357 14.3229C13.3895 14.3229 16.2326 15.3357 16.2326 17.6801V19.0117H7.63867V17.6801C7.63867 15.3357 10.4818 14.3229 11.9357 14.3229Z"
        stroke="none"
      ></path>
      <path
        d="M16.2326 19.0117V19.9117H17.1326V19.0117H16.2326ZM7.63867 19.0117H6.73867V19.9117H7.63867V19.0117ZM11.7685 13.287C13.4579 13.287 14.8364 11.9085 14.8364 10.2191H13.0364C13.0364 10.9144 12.4638 11.487 11.7685 11.487V13.287ZM8.70069 10.2191C8.70069 11.9085 10.0792 13.287 11.7685 13.287V11.487C11.0733 11.487 10.5007 10.9144 10.5007 10.2191H8.70069ZM11.7685 7.15127C10.0792 7.15127 8.70069 8.52974 8.70069 10.2191H10.5007C10.5007 9.52386 11.0733 8.95127 11.7685 8.95127V7.15127ZM14.8364 10.2191C14.8364 8.52974 13.4579 7.15127 11.7685 7.15127V8.95127C12.4638 8.95127 13.0364 9.52386 13.0364 10.2191H14.8364ZM17.1326 17.6801C17.1326 16.0841 16.1487 14.9971 15.0991 14.3547C14.0629 13.7205 12.8256 13.4229 11.9357 13.4229V15.2229C12.4995 15.2229 13.4107 15.4317 14.1595 15.89C14.895 16.3401 15.3326 16.9317 15.3326 17.6801H17.1326ZM17.1326 19.0117V17.6801H15.3326V19.0117H17.1326ZM7.63867 19.9117H16.2326V18.1117H7.63867V19.9117ZM6.73867 17.6801V19.0117H8.53867V17.6801H6.73867ZM11.9357 13.4229C11.0457 13.4229 9.80843 13.7205 8.77217 14.3547C7.72257 14.9971 6.73867 16.0841 6.73867 17.6801H8.53867C8.53867 16.9317 8.97636 16.3401 9.7118 15.89C10.4606 15.4317 11.3718 15.2229 11.9357 15.2229V13.4229Z"
        stroke="none"
      ></path>
    </svg>
  </BaseIcon>
);
