import styles from './Avatar.module.scss';

import { getInitials } from '../../utils/text';

export interface AvatarProps {
  backgroundColor?: string;
  name: string;
  size?: number; // Optional size prop to adjust avatar size if needed
}

export const Avatar = ({ name }: AvatarProps) => {
  return <span className={styles.avatar}>{getInitials(name)}</span>;
};
