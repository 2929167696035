import { useState } from 'react';

import styles from './ScreenShareModal.module.scss';

import {
  SCREEN_SHARE_MODAL_ID,
  SCREEN_SHARE_MODAL_TITLE,
  SCREEN_SHARE_SHOW_TIPS_LABEL,
  SCREEN_SHARE_TIPS
} from '../../../config/screenShareModal';
import { ButtonState } from '../../../constants/buttons';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../Buttons/SecondaryButton/SecondaryButton';
import { Checkbox } from '../../Inputs/Checkbox/Checkbox';
import { Modal } from '../../Modal/Modal';

export interface ScreenShareModalProps {
  onCloseClicked: () => void;
  onShareClicked: (neverShowAgain: boolean) => void;
  open: boolean;
}

export const ScreenShareModal = ({
  open,
  onCloseClicked,
  onShareClicked
}: ScreenShareModalProps) => {
  const [hideTips, setHideTips] = useState(false);

  const handleShareClicked = () => {
    onShareClicked(hideTips);
  };

  return (
    <Modal
      open={open}
      title={SCREEN_SHARE_MODAL_TITLE}
      modalId={SCREEN_SHARE_MODAL_ID}
      onCancel={onCloseClicked}
      rightButtons={
        <PrimaryButton
          text="Share"
          state={ButtonState.Enabled}
          onClick={handleShareClicked}
        />
      }
      leftButton={
        <SecondaryButton
          text="Cancel"
          state={ButtonState.Enabled}
          onClick={onCloseClicked}
        />
      }
    >
      <section className={styles.container}>
        <ul>
          {SCREEN_SHARE_TIPS.map((tip, idx) => (
            <li key={`screen-share-tip-${idx}`}>
              <span>{tip.icon}</span>
              <p>{tip.text}</p>
            </li>
          ))}
        </ul>

        <Checkbox
          label={SCREEN_SHARE_SHOW_TIPS_LABEL}
          checked={hideTips}
          onChange={(value) => setHideTips(value)}
        />
      </section>
    </Modal>
  );
};
