import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';

import { HMSRoomProvider } from '@100mslive/react-sdk';

import notificationStyles from './styles/notifications.module.scss';

import { ErrorHandler } from './components/ErrorHandler/ErrorHandler';
import { EventValidation } from './components/EventValidation/EventValidation';
import { SessionValidation } from './components/SessionValidation/SessionValidation';
import { ROUTES } from './constants/Routes';
import { hmsActions, hmsNotifications, hmsStore } from './hms';
import { JoinPage } from './pages/Join/Join';
import { NotFoundPage } from './pages/NotFound/NotFound';
import { SessionPage } from './pages/Session/Session';
import { SessionEndedPage } from './pages/SessionEnded/SessionEnded';
import AppStateProvider from './providers/AppState';
import MediaRecorderProvider from './providers/MediaRecorderProvider';
import RecordingProvider from './providers/RecordingProvider';
import './services/firebase';
import { Background } from './stories/Background/Background';

function App() {
  return (
    <AppStateProvider>
      <Background>
        <HMSRoomProvider
          isHMSStatsOn={true}
          actions={hmsActions}
          store={hmsStore}
          notifications={hmsNotifications}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path={ROUTES.JOIN_SINGLE_RECORDING}
                element={
                  <EventValidation>
                    <ErrorHandler>
                      <JoinPage />
                    </ErrorHandler>
                  </EventValidation>
                }
              />

              <Route
                path={ROUTES.JOIN_GROUP_RECORDING}
                element={
                  <EventValidation group>
                    <ErrorHandler>
                      <JoinPage group />
                    </ErrorHandler>
                  </EventValidation>
                }
              />

              <Route
                path={ROUTES.RECORDING_SESSION}
                element={
                  <SessionValidation>
                    <RecordingProvider>
                      <MediaRecorderProvider>
                        <SessionPage />
                      </MediaRecorderProvider>
                    </RecordingProvider>
                  </SessionValidation>
                }
              />

              <Route
                path={ROUTES.SESSION_ENDED}
                element={
                  // <SessionValidation>
                  <SessionEndedPage />
                  // </SessionValidation>
                }
              />

              <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />

              <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} />} />
            </Routes>
          </BrowserRouter>
        </HMSRoomProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          theme="light"
          transition={Bounce}
          closeButton={false}
          toastClassName={notificationStyles.container}
        />
      </Background>
    </AppStateProvider>
  );
}

export default App;
