import { FormEvent, useEffect, useState } from 'react';

import styles from './SendMessage.module.scss';

import {
  MESSAGE_INPUT_PLACEHOLDER,
  SEND_BUTTON_LABEL,
  SEND_MESSAGE_FORM_ID,
  SEND_MESSAGE_INPUT_ID
} from '../../../config/Chat';
import { ButtonState, ButtonType } from '../../../constants/buttons';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import {
  TEXT_INPUT_AUTOCOMPLETE,
  TextInput
} from '../../Inputs/TextInput/TextInput';

export interface SendMessageProps {
  sendMessage: (message: string) => Promise<void>;
}

export const SendMessage = ({ sendMessage }: SendMessageProps) => {
  const [buttonState, setButtonState] = useState(ButtonState.Enabled);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setButtonState(
      message.trimEnd().length === 0
        ? ButtonState.Disabled
        : ButtonState.Enabled
    );
  }, [message]);

  const handleMessageChange = (message: string) => {
    setMessage(message);
  };

  const handleMessageSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setButtonState(ButtonState.Loading);
    void sendMessage(message);
    setMessage('');
  };

  return (
    <section className={styles.container}>
      <p className={styles.info}>Your message will be sent to everyone</p>
      <form
        id={SEND_MESSAGE_FORM_ID}
        className={styles.wrapper}
        onSubmit={handleMessageSubmit}
      >
        <TextInput
          id={SEND_MESSAGE_INPUT_ID}
          placeholder={MESSAGE_INPUT_PLACEHOLDER}
          type="text"
          name="message"
          value={message}
          autocomplete={TEXT_INPUT_AUTOCOMPLETE.OFF}
          onChange={handleMessageChange}
        />
        <PrimaryButton
          formId={SEND_MESSAGE_FORM_ID}
          text={SEND_BUTTON_LABEL}
          type={ButtonType.Submit}
          state={buttonState}
        />
      </form>
    </section>
  );
};
