// import { useEffect } from "react";
import styles from './ErrorHandler.module.scss';

import { useAppState } from '../../providers/AppState';

interface ErrorAndLoadingHandlerProps {
  children?: React.ReactNode;
}

export const ErrorHandler = ({ children }: ErrorAndLoadingHandlerProps) => {
  const { error } = useAppState();

  // useEffect(() => {
  //   if (error) clearSessionStorage();
  // }, [error]);

  // const clearSessionStorage = () => {
  //   Object.values(StorageKeys).forEach((key) => {
  //     sessionStorage.removeItem(key);
  //   });
  // };

  if (error) {
    return (
      <div className={styles.wrapper}>
        <h1>{error}</h1>
      </div>
    );
  }

  return <>{children}</>;
};
