export const enum ButtonState {
  Active = 'active',
  Disabled = 'disabled',
  Enabled = 'enabled',
  Loading = 'loading'
}

export const enum ButtonType {
  Button = 'button',
  Submit = 'submit'
}
