import {
  ElementRef,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';

import cs from 'classnames';

import styles from './Modal.module.scss';

import { Close } from '../Icons/Close/Close';

export interface ModalProps extends PropsWithChildren {
  disableClose?: boolean;
  disableEsc?: boolean;
  disableScroll?: boolean;
  leftButton?: ReactNode;
  modalId: string;
  onCancel?: () => void;
  open?: boolean;
  rightButtons?: ReactNode;
  title: string;
}

export const Modal = ({
  title,
  children,
  modalId,
  open = false,
  disableClose = false,
  disableEsc = false,
  disableScroll = false,
  rightButtons,
  leftButton,
  onCancel
}: ModalProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const modalRef = useRef<ElementRef<'div'>>(null);

  // Show the modal with a transition
  useEffect(() => {
    if (open) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);

      return () => clearTimeout(timer);
    }
  }, [open]);

  // Handle escape key press to close modal
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !disableEsc && !disableClose && onCancel) {
        onCancel();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [disableEsc, disableClose, onCancel, open]);

  // Disable scrolling when modal is open
  useEffect(() => {
    if (disableScroll && open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [disableScroll, open]);

  const handleBackdropClick = (e: MouseEvent) => {
    if (!disableClose && e.target === modalRef.current) {
      onCancel?.();
    }
  };

  const handleCancelClick = () => {
    if (!disableClose) {
      onCancel?.();
    }
  };

  if (!open && !isVisible) return null;

  const containerClass = cs({
    [styles.container]: true,
    [styles.open]: open
  });

  const contentClass = cs({
    [styles.content]: true,
    [styles.disableScroll]: disableScroll
  });

  return createPortal(
    <div
      id={modalId}
      ref={modalRef}
      className={containerClass}
      onClick={handleBackdropClick}
    >
      <div className={styles.wrapper}>
        <section className={styles.modal}>
          <header className={styles.header}>
            <h4>{title}</h4>
            {!disableClose && (
              <button aria-label="Close modal" onClick={handleCancelClick}>
                <Close />
              </button>
            )}
          </header>
          <section className={contentClass}>{children}</section>
          <footer className={styles.footer}>
            <div className={styles.rightButtons}>{rightButtons}</div>

            {leftButton}
          </footer>
        </section>
      </div>
    </div>,
    document.body
  );
};
