import { ReactElement } from 'react';

import { Size } from '../../../constants/Size';

export interface IconProps {
  color?: string;
  scale?: number;
  size?: Size;
}

interface BaseIconProps {
  children: ReactElement<IconProps>;
}

export const BaseIcon = ({ children }: BaseIconProps) => children;
